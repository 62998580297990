const Swal = require('sweetalert2');


function transformDates(obj) {
  const { Desde, Hasta, ...rest } = obj;
  const transformDate = (date) => {
      if (!date || date.trim() === '') return null;
      return date.slice(6) + '-' + date.slice(3,5) + '-' + date.slice(0,2) ;
  };
  return {
      ...rest,
      Desde: transformDate(Desde),
      Hasta: transformDate(Hasta),
  };
}

function inversaTransformDates(obj) {
  const { Desde, Hasta, ...rest } = obj;
  const transformDate = (date) => {
      if (!date || date.trim() === '') return null;
      return date.slice(8) + '/' + date.slice(5,7) + '/' + date.slice(0,4);
  };
  return {
      ...rest,
      Desde: transformDate(Desde),
      Hasta: transformDate(Hasta),
  };
}

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  }
});

async function enviarDatos(datosEnviar, base, servidor, usuario, empresa, conexion, fechaActual) {

  let copiaDatos = datosEnviar.map(obj => transformDates(obj));

  let Correctos= []; let Incorrectos = []; let NoIngresados = []; let contador = 0; let lote = 300;
  
  for(let i = 0; i<copiaDatos.length; i+=lote){
    let porcion = copiaDatos.slice(i, i+lote);
    try {

      let pedirIdLegajo = await fetch('https://conectango.ucc.edu.ar/api/IDLEGAJO', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Porcion: porcion,
          Base: base,
          Servidor: servidor
        })
      });

      let porcionLegajos = await pedirIdLegajo.json();
      
      let erroresLegajos = porcionLegajos.filter(obj => obj.Error);
      Incorrectos = Incorrectos.concat(erroresLegajos);
      
      porcionLegajos = porcionLegajos.filter(obj => !obj.Error);

      let pedirIdConcepto = await fetch('https://conectango.ucc.edu.ar/api/IDCONCEPTO', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          PorcionLegajos: porcionLegajos,
          Base: base,
          Servidor: servidor })
      });

      let porcionLegajosConceptos = await pedirIdConcepto.json();

      let erroresConceptos = porcionLegajosConceptos.filter(obj => obj.Error);
      erroresConceptos.forEach(obj => {
          delete obj.ID_LEGAJO;
      });
      Incorrectos = Incorrectos.concat(erroresConceptos);

      porcionLegajosConceptos = porcionLegajosConceptos.filter(obj => !obj.Error);

      let response = await fetch('https://conectango.ucc.edu.ar/api/insertarConceptos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({

          PorcionLegajosConceptos: porcionLegajosConceptos,
          Base: base,
          Servidor: servidor,
          Usuario: usuario,
          Empresa: empresa,
          Conexion: conexion,
          Fecha: fechaActual
        })
      });
  
      let data = await response.json();

      if(data.datosCorrectos.length !== 0){
        Correctos = Correctos.concat(data.datosCorrectos); 
      }
      if(data.datosIncorrectos.length !== 0){
        Incorrectos = Incorrectos.concat(data.datosIncorrectos);
      }
      contador += 1;
    } catch (error) {
      NoIngresados = copiaDatos.slice(contador*lote,);
      break;
    }
  }

  Correctos.forEach(obj => {
    delete obj.ID_LEGAJO;
    delete obj.ID_CONCEPTO;
  });
  Incorrectos.forEach(obj => {
    delete obj.ID_LEGAJO;
    delete obj.ID_CONCEPTO;
  });

  Correctos = Correctos.map(obj => inversaTransformDates(obj));
  Incorrectos = Incorrectos.map(obj => inversaTransformDates(obj));
  NoIngresados = NoIngresados.map(obj => inversaTransformDates(obj));
  return [Correctos,Incorrectos,NoIngresados];
}

async function enviarDatosBORRAR(datosEnviar, base, servidor, usuario, empresa, conexion, fechaActual){

  let copiaDatos = datosEnviar.map(obj => transformDates(obj));

  let Correctos= []; let Incorrectos = []; let NoIngresados = []; let contador = 0; let lote = 300;
  let CorrectosEliminados= []; let IncorrectosEliminados = [];
  
  for(let i = 0; i<copiaDatos.length; i+=lote){
    let porcion = copiaDatos.slice(i, i+lote);
    try {

      let pedirIdLegajo = await fetch('https://conectango.ucc.edu.ar/api/IDLEGAJO', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Porcion: porcion,
          Base: base,
          Servidor: servidor
        })
      });

      let porcionLegajos = await pedirIdLegajo.json();
      
      let erroresLegajos = porcionLegajos.filter(obj => obj.Error);
      IncorrectosEliminados = IncorrectosEliminados.concat(erroresLegajos);
      
      porcionLegajos = porcionLegajos.filter(obj => !obj.Error);

      let pedirIdConcepto = await fetch('https://conectango.ucc.edu.ar/api/IDCONCEPTO', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          PorcionLegajos: porcionLegajos,
          Base: base,
          Servidor: servidor })
      });

      let porcionLegajosConceptos = await pedirIdConcepto.json();

      let erroresConceptos = porcionLegajosConceptos.filter(obj => obj.Error);
      erroresConceptos.forEach(obj => {
          delete obj.ID_LEGAJO;
      });
      IncorrectosEliminados = IncorrectosEliminados.concat(erroresConceptos);

      porcionLegajosConceptos = porcionLegajosConceptos.filter(obj => !obj.Error);
      
      try{
        let existen = await fetch('https://conectango.ucc.edu.ar/api/buscarCoincidenciasEnTabla', {
          method: 'POST',
          headers: {
            'Content-Type' : 'application/json'
          },
          body: JSON.stringify({

            PorcionLegajosConceptos: porcionLegajosConceptos,
            Base: base,
            Servidor: servidor 
          })
        })

        existen = await existen.json();

        if(existen.datosIncorrectos.length !== 0){
          let erroresCoincidencias = existen.datosIncorrectos.filter(obj =>obj.Error);
          erroresCoincidencias.forEach(obj => {
            delete obj.ID_LEGAJO;
            delete obj.ID_CONCEPTO;
          })
          IncorrectosEliminados = IncorrectosEliminados.concat(erroresCoincidencias);
        }

        let porcionFinal = existen.datosCorrectos;

        try{
          let fecha = fechaActualPBase();
          let hora = horaActualPBase();
          let FecMod = fecha+' '+hora;
          let eliminar = await fetch('https://conectango.ucc.edu.ar/api/eliminarConceptoParticular', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              PorcionFinal: porcionFinal,
              Base: base,
              Servidor: servidor,
              Usuario: usuario,
              Empresa: empresa,
              Conexion: conexion,
              fecha: FecMod })
          });

          let reseliminar = await eliminar.json();

          if(reseliminar.datosCorrectos.length !== 0){
            CorrectosEliminados = CorrectosEliminados.concat(reseliminar.datosCorrectos); 
          }
          if(reseliminar.datosIncorrectos.length !== 0){
            IncorrectosEliminados = IncorrectosEliminados.concat(reseliminar.datosIncorrectos);
          }
        }catch(err){
          console.log("Error en la eliminacion: "+err);
        }
      }catch(err){
        console.log("Error al buscar el concepto particular "+err);
      }

      contador += 1;
    } catch (error) {
      NoIngresados = copiaDatos.slice(contador*lote,);
      break;
    }
  }

  Correctos.forEach(obj => {
    delete obj.ID_LEGAJO;
    delete obj.ID_CONCEPTO;
  });
  Incorrectos.forEach(obj => {
    delete obj.ID_LEGAJO;
    delete obj.ID_CONCEPTO;
  });
  CorrectosEliminados.forEach(obj => {
    delete obj.ID_LEGAJO;
    delete obj.ID_CONCEPTO;
  });
  IncorrectosEliminados.forEach(obj => {
    delete obj.ID_LEGAJO;
    delete obj.ID_CONCEPTO;
  });

  /* Correctos = Correctos.map(obj => inversaFECHAYHORA(obj))
  Incorrectos = Incorrectos.map(obj => inversaFECHAYHORA(obj)) */
  CorrectosEliminados = CorrectosEliminados.map(obj => inversaTransformDates(obj));
  IncorrectosEliminados = IncorrectosEliminados.map(obj => inversaTransformDates(obj));
  NoIngresados = NoIngresados.map(obj => inversaTransformDates(obj));
/* 
  let SetCorrectos =  Array.from(new Set(Correctos.map(JSON.stringify)), JSON.parse);
  let SetIncorrectos =  Array.from(new Set(Incorrectos.map(JSON.stringify)), JSON.parse);
  let SetNoIngresados =  Array.from(new Set(NoIngresados.map(JSON.stringify)), JSON.parse); */
  return [CorrectosEliminados,IncorrectosEliminados,NoIngresados];
}

async function enviarSueldos(datos, config, base, servidor, usuario){
  let Correctos= []; let Incorrectos = []; let NoIngresados = []; let contador = 0; let lote = 300; let MENSAJE = 'Proceso completado.'; let ERROR=false;
  for(let i = 0; i<datos.length; i+=lote){
    let porcion = datos.slice(i, i+lote);
    try{
      let fecha = fechaActualPBase();
      let hora = horaActualPBase();
      let FecMod = fecha+' '+hora;
      let proceso = await fetch('https://conectango.ucc.edu.ar/api/actualizarSueldos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Data: porcion, 
          Configuracion: config, 
          Fecha: FecMod,
          Base: base,
          Servidor: servidor,
          idUsuario: usuario.idUsuario,
          idEmpresa: usuario.idEmpresa,
          idConexion: usuario.idConexiones
        })
      })
      let data = await proceso.json();
      if(data.ERROR){
        ERROR = true;
        MENSAJE= data.MENSAJE;
        NoIngresados = datos.slice(contador*lote,);
        break;
      }else{
        if(data.DATAC.length !== 0){
          Correctos = Correctos.concat(data.DATAC); 
        }
        if(data.DATAI.length !== 0){
          Incorrectos = Incorrectos.concat(data.DATAI);
        }
        contador += 1;
      }
      
    } catch (error) {
      NoIngresados = datos.slice(contador*lote,);
      break;
    }
  }
  return ({
    CORRECTOS: Correctos,
    INCORRECTOS: Incorrectos,
    NOINGRESADOS: NoIngresados,
    Mensaje: MENSAJE,
    Error: ERROR});
}

function fechaActual(){
  let fecha = new Date();
  let dia = fecha.getDate();
  let mes = fecha.getMonth()+1;
  let año = fecha.getFullYear();
  let hora = fecha.getHours();
  let minutos = (fecha.getMinutes() < 10)? '0'+''+fecha.getMinutes():fecha.getMinutes();
  return ( dia+ '-' + mes + '-' + año + ' ' + hora +':'+ minutos);
}

function fechaActualPBase(){
  let fecha = new Date();
  return (((fecha.getDate() <10)?('0'+fecha.getDate()):fecha.getDate()) + '-' + (((fecha.getMonth()+1)<10)?('0'+(fecha.getMonth()+1)):(fecha.getMonth()+1)) + '-' + fecha.getFullYear());
}

function horaActualPBase(){
  let fecha = new Date();
  let horas = (fecha.getHours()<10)? '0'+fecha.getHours() : fecha.getHours();
  let minutos = (fecha.getMinutes()<10)? '0'+fecha.getMinutes() : fecha.getMinutes();
  let seg = (fecha.getSeconds()<10)? '0'+fecha.getSeconds() : fecha.getSeconds();
  return (horas+':'+minutos+':'+seg);
}

/* function inversaFECHAYHORA(obj){
  const { FecMod, ...rest } = obj;
  const transformDate = (date) => {
      if (!date || date.trim() === '') return null;
      let fecha = '';
      for(let i = date[0]; i<date.length(); i++){
        if(i === '-'){
          fecha += '/'
        }else{
          fecha+=date[i];
        }
      }
  };
  return {
      ...rest,
      FecMod: transformDate(FecMod),
  };
} */

async function listarEmpresas(){
  try{
    let respuesta = await fetch('https://conectango.ucc.edu.ar/api/listarEmpresas');
    let data = await respuesta.json();
    return data;
  }catch (err){
    return {ERROR: true, MENSAJE: 'Error al realizar la consulta'}
  }
} 


async function listarTodasEmpresas(){
  try{
    let respuesta = await fetch('https://conectango.ucc.edu.ar/api/listarTodasEmpresas');
    respuesta = await respuesta.json();
    if(respuesta.ERROR){
      Toast.fire({
        icon: "error",
        title: respuesta.MENSAJE
      });
      return []
    }else{
      if(respuesta.CONTENIDO.length === 0){
        return []
      }
      else{
        return (respuesta.CONTENIDO.map(obj => obj.NOMBRE_EMP));
      }
    }
  }catch (err){
    console.log({ERROR: true, MENSAJE:"Error: " + err})
  }
}

async function listarTodasEmpresasConDatos(){
  try{
    let respuesta = await fetch('https://conectango.ucc.edu.ar/api/listarEmpresasConDatos');
    let data = await respuesta.json();
    return data;
  }catch(err){
    return ({ERROR: true, MENSAJE: 'Error de conexión. Intente nuevamente.'})
  }
}

async function obtenerEmpresa(datos){
  try{
    let respuesta = await fetch('https://conectango.ucc.edu.ar/api/consultaEmpresa', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({Nombre: datos})
    });

    if(respuesta.ok){
      let data = await respuesta.json();
      return data;
    } else{
      console.log("Error en la solicitud: " + respuesta.status)
    }
  }catch (err){
    console.log("Error: " + err)
  }
} 

async function EmpresaABMAlta(Nombre, Principal, Miniatura){
  try{
    const data = new FormData();
    let fechamod = fechaActualPBase();
    data.append('Fecha', fechamod)
    data.append('Nombre', Nombre)
    data.append('LogoPrincipal', Principal);
    if(Miniatura !== null){
      data.append('LogoMiniatura', Miniatura);
    }else{
      data.append('LogoMiniatura', 'sinLogo');
    }
    let resultado =  await fetch('https://conectango.ucc.edu.ar/api/nuevaEmpresa', {
      method: 'POST',
      body: data
    });
    resultado = resultado.json();
    return resultado;
  }
  catch(err){
    return {ERROR: true, MENSAJE: 'ERROR: '+err}
  }
}


async function EmpresaABMMod(NomActual, NomNuevo, estado){
  let fechamod = fechaActualPBase();
  try{
    let busqueda = await fetch('https://conectango.ucc.edu.ar/api/empresaConConexionesActivas', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({nomEmpresa: NomActual})
    });
    busqueda = await busqueda.json();
    if(busqueda.ERROR){
      return busqueda;
    }
    else{
      if(busqueda.ConAct){
        return {ERROR: true, MENSAJE: "No se puede modificar una empresa que tenga conexiones activas."};
      }else{
        let respuesta = await fetch('https://conectango.ucc.edu.ar/api/ModificarEmpresa', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({NombreActual : NomActual, NuevoNombre : NomNuevo, Estado : estado, Fecha: fechamod})
        });
        respuesta = respuesta.json();
        return respuesta;
      }
    }
  }catch(err){
    return {ERROR: true, MENSAJE: "Error en proceso de modificación, intente de nuevo."};
  }
}

async function EmpresaABMBaja(nombre){
  let fechamod = fechaActualPBase();
  try{
    let busqueda = await fetch('https://conectango.ucc.edu.ar/api/empresaConConexionesActivas', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({nomEmpresa: nombre})
    });
    busqueda = await busqueda.json();
    if(busqueda.ERROR){
      return busqueda;
    }else{
      if(busqueda.ConAct){
        return {ERROR: true, MENSAJE: "No se puede eliminar una empresa que tenga conexiones activas."};
      }else{
        let respuesta = await fetch('https://conectango.ucc.edu.ar/api/BajarEmpresa', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({Nombre : nombre, Fecha: fechamod})
        });
        respuesta = respuesta.json();
        return respuesta;
      }
    }
  }catch(err){
    return {ERROR: true, MENSAJE: "Error en el proceso de la baja, intente de nuevo."};
  }
}


async function listarTodasConexiones(){
  try{
    let respuesta = await fetch('https://conectango.ucc.edu.ar/api/listarTodasConexiones');
    let data = await respuesta.json();
    if(data.length === 0){
      Toast.fire({
        icon: "warning",
        title: "No hay conexiones registradas"
      });
      /* alert("No hay "); */
      return ([])
    }
    else{
      return (data.map(obj => obj.NOMBRE_CONEXION));
    }
  }catch(err){
    console.log("Error: "+err)
  }
}

async function ConexionABMAlta(empresaNombre, conexionNombre, servidor, base){
  try{
    let resultado = await fetch('https://conectango.ucc.edu.ar/api/nuevaConexion', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        Empresa: empresaNombre, 
        Conexion: conexionNombre,
        Base: base, 
        Servidor: servidor})
    });
    resultado = await resultado.json();
    return resultado;
  }catch(err){
    return {ERROR: true, MENSAJE: 'Error en el proceso, intente nuevamente.'}
  }
}

async function ConexionABMMod(conexionSelect, nombre, base, servidor, estado, idEmp){
  try{
    if(!estado){
      let busquedaUsuariosConectados = await fetch('https://conectango.ucc.edu.ar/api/tieneUsuariosConectados', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          NomCon: conexionSelect,
          idEMP: idEmp
        })
      })
      busquedaUsuariosConectados = await busquedaUsuariosConectados.json();
      if(busquedaUsuariosConectados.ERROR){
        return busquedaUsuariosConectados;
      }
    }
    let resultado = await fetch('https://conectango.ucc.edu.ar/api/modificarConex', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Conexion: conexionSelect, 
          Nombre: nombre,
          Base: base, 
          Servidor: servidor,
          idEMP: idEmp,
          Estado: estado})
    });
    resultado = await resultado.json();
    return resultado;
  }catch(err){
    console.log("Error: "+err);
    return {ERROR: true, MENSAJE: 'Error de conexión, intente nuevamente.'}
  }

}

async function ConexionABMBaja(nomCon, idEmp){
  try{
    let busquedaUsuariosConectados = await fetch('https://conectango.ucc.edu.ar/api/tieneUsuariosConectados', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        NomCon: nomCon,
        idEMP: idEmp
      })
    })
    let busqueda = await busquedaUsuariosConectados.json()
    if(busqueda.ERROR){
      return busqueda;
    }else{
      try{
        let resultado = await fetch('https://conectango.ucc.edu.ar/api/bajaConexion', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            NomCon: nomCon,
            idEMP: idEmp
          })
        })
        let res = await resultado.json();
        return res
      }
      catch(err){
        return {ERROR: true, MENSAJE:'Error en el proceso de la baja.'};
      }
    }
  }catch(err){
    return {ERROR: true, MENSAJE:'Error en el proceso de la busqueda de conexiones en uso.'}
  }
}

async function cargarConexionesABM(){
  try{
    let get = await fetch('https://conectango.ucc.edu.ar/api/listarConexionesABM');
    get = await get.json();
    return get
  }catch(err){
    return({ERROR:true, MENSAJE:'ERROR: '+err})
  }
}

async function cargarConexionesABMNOADMIN(empresa){
  try{
    let get = await fetch('https://conectango.ucc.edu.ar/api/listarConexionesABMNOADMIN',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({Empresa: empresa})
    })
    get = await get.json();
    return get
  }catch(err){
    return({ERROR:true, MENSAJE:'ERROR: '+err})
  }
}

async function cargarConfigSBABM(idConexion){
  let get = await fetch('https://conectango.ucc.edu.ar/api/listarConfigSBABM',{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(
      {ID: idConexion})
  });
  let resget = await get.json();
  if(resget.ERROR){
    console.log(resget.MENSAJE);
    return resget;
  }
  else{
    let arrayConfig = [];
    let arrayRes = resget.CONTENIDO;
    if(arrayRes.length !== 0){
      arrayRes.forEach((config)=>{
        let nuevo ={
          idConfig: config.ID_CONFIG_SUELDO_BASICO,
          Nombre: config.NOMBRE_CONFIGURACION,
          Tabla: config.NOMBRE_TABLA_SUELDOS,
          Categorias: config.CATEGORIAS,
          Codigos: config.COD_CATEGORIA,
          idConexiones: config.ID_CONEXIONES
        }
        arrayConfig.push(nuevo);
      })
    }
    return {ERROR: false, CONTENIDO: arrayConfig}
  }
}
/* 
async function cargarConfigSBABMNOADMIN(empresa){
  let get = await fetch('https://conectango.ucc.edu.ar/api/listarConfigSBABMNOADMIN', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({Empresa: empresa})
    })
  let resget = await get.json();
  if(resget.ERROR){
    console.log(resget.MENSAJE);
    return resget;
  }
  else{
    let arrayConfig = [];
    let arrayRes = resget.CONTENIDO;
    if(arrayRes.length !== 0){
      arrayRes.forEach((config)=>{
        let nuevo ={
          idConfig: config.ID_CONFIG_SUELDO_BASICO,
          idEmpresa: config.ID_EMPRESA,
          idConexion: config.ID_CONEXION,
          Tabla: config.NOMBRE_TABLA_SUELDOS,
          Activo: config.ACTIVO,
          Categorias: config.CATEGORIAS,
          Codigos: config.COD_CATEGORIA
        }
        arrayConfig.push(nuevo);
      })
      for(const config of arrayConfig){
        let empresa = await buscarEmpresaXID(config.idEmpresa);
        if(empresa.ERROR){
          console.log("ERROR: "+empresa.MENSAJE);
        }
        else{
          config.NombreEmpresa = empresa;
          let conexion = await buscarConexionXID(config.idConexion);
          if(conexion.ERROR){
            console.log("Error: "+conexion.MENSAJE);
          }
          else{
            config.NombreConexion = conexion;
          }
        }
      }
    }
    return {ERROR: false, CONTENIDO: arrayConfig}
  }
} */

async function BuscarCategoriasABMAlta(idConexion, tabla){
  try{
    let buscar = await fetch('https://conectango.ucc.edu.ar/api/buscarCategorias', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        IdConexion: idConexion, 
        Tabla: tabla
      })
    });
    buscar = await buscar.json();
    return buscar;
  }catch{
    return ({ERROR: true, MENSAJE: 'Fallo en la conexión, intente de nuevo.'})
  }
}

async function bajaConfigABM(id){
  try{
    let borrar = await fetch('https://conectango.ucc.edu.ar/api/bajaConfigABM', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {ID:id})
      })
      borrar = await borrar.json();
      return borrar;
  }catch(err){
    return {ERROR: true, MENSAJE: 'Error de conexión.'}
  }
}

async function ConfigABMMod(item, nuevaTabla, estado){
  try{
    let modificacion = await fetch('https://conectango.ucc.edu.ar/api/modConfigABM', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        Empresa: item.idEmpresa, 
        Conexion: item.idConexion, 
        Tabla: item.Tabla,
        NuevaTabla: nuevaTabla,
        Estado: estado})
    })

    modificacion = await modificacion.json();
    if(modificacion.Estado){
      return true;
    }
    else{
      return false;
    }
  }catch(err){
    console.log("Error: "+err)
    return false
  }
}

async function ConfigABMAlta(nombre, conexion,tabla,idCategorias, codigosSeleccionadas){
  try{
    let alta = await fetch('https://conectango.ucc.edu.ar/api/altaConfigABM', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        Nombre: nombre,
        Conexion: conexion,
        Tabla: tabla,
        IdCategorias: idCategorias,
        Codigos: codigosSeleccionadas
      })
    })
    alta = await alta.json();
    return alta;

  }catch(err){
    console.log("Error: "+err);
    return ({ERROR:true, MENSAJE:'Fallo en la conexión.'});
  }
}

async function cargarRolesABM(){
  let buscar = await fetch('https://conectango.ucc.edu.ar/api/listarRolesABM');
  buscar = await buscar.json()
  if(buscar.ERROR){
    console.log(buscar.MENSAJE);
    return({MENSAJE: buscar.MENSAJE, ERROR: true});
  }
  else{
    let arrayFinal = [];
    let roles = buscar.ROLES;
    let permisos = buscar.PERMISOS;
    roles.forEach((rol)=>{
      let nuevo ={
        idRol: rol.ID_ROL,
        NombreRol: rol.ROL_PARTICULAR,
        Activo: rol.ACTIVO
      }
      permisos.forEach((permisos)=>{
        if(permisos.ID_ROL === rol.ID_ROL){
          nuevo.AgrCon = permisos.RRHH_AGREGAR_CONCEPTOS;
          nuevo.ElimCon = permisos.RRHH_ELIMINAR_CONCEPTOS;
          nuevo.ActSueldo = permisos.RRHH_ACTUALIZACION_SUELDOB;
          nuevo.TabEmp = permisos.TABLAS_EMPRESAS;
          nuevo.TabConex = permisos.TABLAS_CONEXIONES;
          nuevo.TabConfig = permisos.TABLAS_CONFIG_SUELDOB;
          nuevo.TabRoles = permisos.TABLAS_ROLES;
          nuevo.TabUsuarios = permisos.TABLAS_USUARIOS;
        }
      })
      arrayFinal.push(nuevo);
    })
    return arrayFinal;
  }
}

async function RolesABMAlta(nombreRol, agregar, eliminar, actualizar, empresas, conexiones, configuracion, roles, usuarios, dash){
  try{
    let alta = await fetch('https://conectango.ucc.edu.ar/api/altaRol',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        NombreRol: nombreRol,
        Agregar: agregar,
        Eliminar: eliminar,
        Actualizar: actualizar,
        Empresas: empresas,
        Conexiones: conexiones,
        Config: configuracion,
        Roles: roles,
        Usuarios: usuarios
      })
    })
    alta = await alta.json();
    return alta;
  }catch(err){
    console.log("error: "+err)
    return {ERROR:true, MENSAJE: 'ERROR: '+err}
  }
}

async function RolesABMMod(item, nombreRol, estado, agregar, eliminar, actualizar, empresas, conexiones, configuracion, roles,usuarios, dash){
  try{
    let mod = await fetch('https://conectango.ucc.edu.ar/api/modRol',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idRol: item.idRol,
        NuevoNombreRol: nombreRol,
        Estado: estado,
        Agregar: agregar,
        Eliminar: eliminar,
        Actualizar: actualizar,
        Empresas: empresas,
        Conexiones: conexiones,
        Config: configuracion,
        Roles: roles,
        Usuarios: usuarios
      })
    })
    mod = await mod.json();
    return mod;
  }catch(err){
    console.log("error: "+err)
    return {ERROR: true,  MENSAJE: 'ERROR: '+err}
  }
}

async function RolesABMBaja(item){
  try{
    let resultado = await fetch('https://conectango.ucc.edu.ar/api/bajaRol',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idRol: item.idRol
      })
    })
    resultado = await resultado.json();
    return resultado.ESTADO;
  }catch(err){
    console.log(err);
    return -3
  }
}

async function login(usuario, passw){
  try{
    let confirmar = await fetch('https://conectango.ucc.edu.ar/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user : usuario,
        clave: passw
      })
    })
    confirmar = await confirmar.json();
    return confirmar;
  }catch(err){
    return {ERROR: true, MENSAJE:"Error en el proceso."}
  }
}

async function cargarUsuariosABM(Rol, idEmpresa){
  let buscar = (Rol === 'ADMIN')? 
                await fetch('https://conectango.ucc.edu.ar/api/listarUsuariosABM')
                :
                await fetch('https://conectango.ucc.edu.ar/api/listarUsuariosABMNOADMIN', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    EMP: idEmpresa
                  })
                });
  buscar = await buscar.json();
  if(buscar.ERROR){
    return({MENSAJE: buscar.MENSAJE, ERROR: true});
  }else{
    let array = [];
    let registros = buscar.CONTENIDO;
    registros.forEach((fila)=>{
      let nuevo ={
        idUsuario: fila.ID_USUARIO,
        MailUsuario: fila.MAIL,
        NombreUsuario: fila.NOMBRE,
        ApellidoUsuario: fila.APELLIDO,
        Estado: fila.ESTADO,
        idRol: fila.ID_ROL,
        Rol: fila.ROL_PARTICULAR,
        ActSueldo: fila.RRHH_ACTUALIZACION_SUELDOB,
        idEmpresaUsuario: fila.ID_EMPRESA,
        EmpresaUsuario: fila.NOMBRE_EMP
      }
      array.push(nuevo);
    })
    return ({CONTENIDO: array, ERROR: false});
  }
}

async function UsuariosABMAlta(nombre, apellido, clave, mail, Emp, idRol){
  try{
    let fecha = fechaActualPBase();
    let insertar = await fetch('https://conectango.ucc.edu.ar/api/AltaUsuario', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        Nombre: nombre,
        Apellido: apellido,
        Clave: clave,
        Mail:mail,
        EMP: Emp,
        idROL: idRol,
        FecMod: fecha
      })
    })
    insertar = await insertar.json();
    return insertar;
  }catch(err){
    console.log("Error: "+err)
    return ({ERROR: true, MENSAJE: 'Fallo en la conexión, intente de nuevo.'})
  }
}

async function UsuariosABMAltaCopia(nombre, apellido, clave, mail, idUsuCop	, idEmp, idRol){
  try{
    let fecha = fechaActualPBase();
    let insertarUsuario = await fetch('https://conectango.ucc.edu.ar/api/AltaUsuarioCopia', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        Nombre: nombre,
        Apellido: apellido,
        Clave: clave,
        Mail:mail,
        idEMP: idEmp,
        idROL: idRol,
        idUSU: idUsuCop,
        FecMod: fecha
      })
    })
    insertarUsuario = await insertarUsuario.json();
    return insertarUsuario;
  }catch(err){
    console.log("Error: "+err)
    return ({ERROR: true, MENSAJE: 'Fallo en la conexión, intente de nuevo.'})
  }
}

async function UsuariosABMMod(item, mail, nombre, apellido, clave, emp, idRol, estado){
  try{
    let fecha = fechaActualPBase();
    let insertar = await fetch('https://conectango.ucc.edu.ar/api/ModUsuario', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idUsuario : item.idUsuario,
        Mail: mail,
        Nombre: nombre,
        Apellido: apellido,
        Clave: clave,
        EMP: emp,
        idROL: idRol,
        FecMod: fecha,
        Estado: estado
      })
    })
    insertar = await insertar.json();
    return insertar
  }catch(err){
    console.log("Error: "+err)
    return ({ERROR: true, MENSAJE:'Fallo en la conexión, intente de nuevo.'})
  }
}

async function UsuariosABMBaja(item){
  try{
    let fecha = fechaActualPBase();
    let baja = await fetch('https://conectango.ucc.edu.ar/api/BajaUsuario', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idUsuario : item.idUsuario,
        FecMod: fecha
      })
    })
    baja = await baja.json();
    if(baja){
      return true;
    }else{
      return false;
    }
  }catch(err){
    console.log("Error: "+err)
    return false
  }
}

async function listarUSUARIO_CONEXION(usuario){
  try{
    let respuesta = await fetch('https://conectango.ucc.edu.ar/api/listarUSUARIO_CONEXION', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({User: usuario})
    });
    let data = await respuesta.json();
    return data
  }catch(err){
    return ({ERROR: true, MENSAJE: err})
  }
}

async function USUARIOCONEXIONES(arrayIdsSi, arrayIdsNo, idConXDef, idUsu){
  try{
    let respuesta = await fetch('https://conectango.ucc.edu.ar/api/USUARIO_CONEXIONES', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ArrayIdsSi: arrayIdsSi,
        ArrayIdsNo: arrayIdsNo,
        IdConXDef: idConXDef,
        IdUsu: idUsu
      })
    });
    let data = await respuesta.json();
    return data
  }catch(err){
    return ({ERROR: true, MENSAJE: err})
  }
}

export default{
  login,
  enviarDatos,
  enviarDatosBORRAR,
  enviarSueldos,
  obtenerEmpresa,
  listarEmpresas,
  listarTodasEmpresas,
  listarTodasEmpresasConDatos,
  fechaActual,
  fechaActualPBase,
  EmpresaABMAlta,
  EmpresaABMMod,
  EmpresaABMBaja,
  listarUSUARIO_CONEXION,
  listarTodasConexiones,
  cargarConexionesABM,
  cargarConexionesABMNOADMIN,
  ConexionABMAlta,
  ConexionABMMod,
  ConexionABMBaja,
  cargarConfigSBABM,
  /* cargarConfigSBABMNOADMIN, */
  ConfigABMAlta,
  BuscarCategoriasABMAlta,
  bajaConfigABM,
  ConfigABMMod,
  cargarRolesABM,
  RolesABMAlta,
  RolesABMBaja,
  RolesABMMod,
  cargarUsuariosABM,
  UsuariosABMAlta,
  UsuariosABMAltaCopia,
  UsuariosABMMod,
  UsuariosABMBaja,
  USUARIOCONEXIONES
}; 

/*------------------------------------TESTING-------------------------------------*/
