import { useEffect, useState } from "react";


const PantallaHome = ({path}) => {

  const [imagen, setImagen] = useState(path);

  useEffect(()=>{
    setImagen(path)
  }, [path])

  return(
    <>
      <img className='logo' alt='logo de la entidad' src={imagen}/>
      <h2 className="title-secondary text-uppercase font-weight-extra-bold">Bienvenido!</h2>  
    </>
  );
};

export default PantallaHome;