import { useState, useRef } from "react";
import miScript from '../conexion.js';
import Swal from 'sweetalert2';
const CryptoJS = require('crypto-js');

function UsuariosABM(
  { data, 
    cargandoset, 
    funcionCargarABM, 
    EMPRESASDISPONIBLES, 
    enSesion, 
    funcionUSUARIO_CONEXIONES, //recarga el componente
    dataUsuario_Conexiones,
    VolverHome }
  ) {

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  const Alerta = Swal.mixin({
    toast: false,
    position: "center",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  const RecargaAlerta = Swal.mixin({
    toast: false,
    position: "center",
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: 'Continuar',
    cancelButtonText: 'Cancelar',
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  

  const [rolesABM, setRolesABM] = useState([]);

  async function cargarRoles() {
    const rolesBuscados = await miScript.cargarRolesABM();
    if (rolesBuscados.ERROR) {
      Toast.fire({
        icon: "warning",
        title: rolesBuscados.MENSAJE
      });
    } else {
      setRolesABM(rolesBuscados);
    }
  }

  function encriptar(password) {
    const hash = CryptoJS.SHA256(password);
    const textoCifrado = hash.toString(CryptoJS.enc.Hex);
    return textoCifrado;
  }

  const [itemSeleccionado, setItemSeleccionado] = useState(null);

  //ABM USUARIOS
  const [mostrarPantallaABM, setMostrarPantallaABM] = useState(true);
  const [mostrarMensajeAlta, setMostrarMensajeAlta] = useState(false);
  const [mostrarMensajeBaja, setMostrarMensajeBaja] = useState(false);
  const [mostrarMensajeMod, setMostrarMensajeMod] = useState(false);
  //ABM USUARIO_CONEXIONES
  const [USUARIOCONEXIONES, setUSUARIOCONEXIONES] = useState(false);

  const [conexionesABM, setConexionesABM] = useState([])

  async function cargarConexionesABM(usu) {
    let conexionesBuscadas;
    if (usu.Rol === 'ADMIN') {
      conexionesBuscadas = await miScript.cargarConexionesABM();
    } else {
      conexionesBuscadas = await miScript.cargarConexionesABMNOADMIN(usu.EmpresaUsuario);
    }
    if (conexionesBuscadas.ERROR) {
      setConexionesABM([])
    } else {
      if (conexionesBuscadas.CONTENIDO.length === 0) {
        setConexionesABM([]);
      } else {
        let i = 0; let conexionesActuales = [];

        while (i < conexionesBuscadas.CONTENIDO.length) {
          if(conexionesBuscadas.CONTENIDO[i].ACTIVO){
            let nuevo = {
              idConexion: conexionesBuscadas.CONTENIDO[i].ID_CONEXIONES,
              Nombre: conexionesBuscadas.CONTENIDO[i].NOMBRE_CONEXION,
              Servidor: conexionesBuscadas.CONTENIDO[i].SERVIDOR,
              BaseDatos: conexionesBuscadas.CONTENIDO[i].BASE_DATOS,
              Activo: conexionesBuscadas.CONTENIDO[i].ACTIVO,
              idEmpresaUsuario: conexionesBuscadas.CONTENIDO[i].EMPRESAID,
              EmpresaUsuario: conexionesBuscadas.CONTENIDO[i].NOMBRE_EMP
            }
            conexionesActuales.push(nuevo);
          }
          i += 1;
        }
        setConexionesABM(conexionesActuales);
      }
    }
  };

  const [USUARIOCONEXIONESAlta, setUSUARIOCONEXIONESAlta] = useState(false);

  //misma funcion que funcionUSUARIOCONEXIONES pero no renderiza el componente
  const [USUARIO_CONEXIOBESABM, setUSUARIO_CONEXIOBESABM] = useState([]);

  const cargarUSUARIO_CONEXIONABM = async (usuario) => {
    const conexiones = await miScript.listarUSUARIO_CONEXION(usuario);
    if (conexiones.ERROR) {
      Toast.fire({
        icon: "error",
        title: conexiones.MENSAJE
      }); 
      setUSUARIO_CONEXIOBESABM([]);
      return [];
    }
    else {
      if (conexiones.CONTENIDO.length !== 0) {
        let array = conexiones.CONTENIDO;
        let nuevoArray = [];
        array.forEach((fila) => {
          let nuevo = {
            idConexion: fila.ID_CONEXIONES,
            nombre: fila.NOMBRE_CONEXION,
            servidor: fila.SERVIDOR,
            base: fila.BASE_DATOS,
            XDef: fila.CONEXION_POR_DEFECTO,
            idUsuario: fila.ID_USUARIO,
            idEmpresa: fila.EMPRESAID,
            empresa: fila.NOMBRE_EMP,
            LogPrin: fila.LOGOPRINCIPAL,
            LogMin: fila.LOGOMINIATURA
          }
          nuevoArray.push(nuevo);
        });
        setUSUARIO_CONEXIOBESABM(nuevoArray);

        return conexiones.CONTENIDO;
      }else{
        setUSUARIO_CONEXIOBESABM([]);
        return [];
      }
    }
  };

  async function prepararUSUARIOCONEXIONESAlta(){
    let nroConexionesdelusuario = await cargarUSUARIO_CONEXIONABM(itemSeleccionado.idUsuario);
    if(nroConexionesdelusuario.length !== 0){
      nroConexionesdelusuario.forEach((fila)=>{
        setConexionesSeleccionadas((prevConexiones) => ({
          ...prevConexiones,
          [fila.ID_CONEXIONES]: {
            seleccionado: true
          }
        }))
        if(fila.CONEXION_POR_DEFECTO){
          setConXDefAlta(fila.ID_CONEXIONES);
        }
      })
    }
    await cargarConexionesABM(itemSeleccionado);
  }

  const [conexionesSeleccionadas, setConexionesSeleccionadas] = useState({});

  const manejarSeleccionConexion = (con) =>{
    setConexionesSeleccionadas((prevConexiones) => ({
      ...prevConexiones,
      [con.idConexion]: {
        seleccionado: !prevConexiones[con.idConexion]?.seleccionado
      }
    }))
  }

  const [ConXDefAlta, setConXDefAlta] = useState(-1);

  function manejarAltaUSUARIOCONEXIONES(){
    if((Object.keys(conexionesSeleccionadas).length === 0)){
      Alerta.fire({
        icon:'warning',
        title: 'Seleccione al menos una de las conexiones '
      })
    }else{
      if(ConXDefAlta === -1){
        Alerta.fire({
          icon:'warning',
          title: 'Seleccione una conexión como "Por defecto".'
        })
      }else{
        let ArrayConexionesSeleccionadas = [];
        let ArrayConexionesDeseleccionadas = [];
        Object.entries(conexionesSeleccionadas)
        .forEach(([id, estado]) => {
            if (estado.seleccionado) {
              ArrayConexionesSeleccionadas.push(id);
            } else {
              ArrayConexionesDeseleccionadas.push(id);
            }
          });
        if(ArrayConexionesSeleccionadas.includes(ConXDefAlta+'')){
          if(ArrayConexionesDeseleccionadas.includes(enSesion.idConexiones+'')){
            RecargaAlerta.fire({
              icon:'warning',
              title:'ATENCION!',
              text: 'Se está por eliminar la conexión que se está utilizando. \nSi continua se recargará la página al finalizar.'
            }).then((result) => {
              if (result.isConfirmed) {
                setUSUARIOCONEXIONESAlta(false);
                USUARIOCONEXIONESDarAlta(ArrayConexionesSeleccionadas,ArrayConexionesDeseleccionadas);
              }
            });
          }else{
            setUSUARIOCONEXIONESAlta(false);
            USUARIOCONEXIONESDarAlta(ArrayConexionesSeleccionadas,ArrayConexionesDeseleccionadas);
          }
          
        }else{
          Alerta.fire({
            icon:'warning',
            title: 'Una conexion por defecto no puede estar desactivada..'
          })
        }
      }
    }
  }

  async function USUARIOCONEXIONESDarAlta(ConexionesSelec, ConexionesNoSelec){
    try{
      let respuesta = await miScript.USUARIOCONEXIONES(ConexionesSelec, ConexionesNoSelec, ConXDefAlta, itemSeleccionado.idUsuario)
      if(respuesta.ERROR){
        Alerta.fire({
          icon:'error',
          title: respuesta.MENSAJE
        }) 
      }else{
        Alerta.fire({
          icon:'success',
          title: respuesta.MENSAJE
        });
      }
    }catch(err){
      Alerta.fire({
        icon:'error',
        title:'Error en el proceso de Usuarios-Conexiones.'
      })
    }
    setDefault();
    if(ConexionesNoSelec.includes(enSesion.idConexiones+'')){
      /* Recarga.fire({icon:'warning',title:'ATENCION!',text: 'Se eliminó la conexión que se está utilizando.\nSe recargará la página.'}) */
      Alerta.fire({
        icon:'warning',
        title:'ATENCION!',
        text: 'Se eliminó la conexión que se está utilizando.\nSe recargará la página.'
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer || result.isDismissed) {
          window.location.reload();
        }
      });
    }
    await funcionUSUARIO_CONEXIONES(itemSeleccionado.idUsuario);
    setUSUARIOCONEXIONES(true);
  }

  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [clave, setClave] = useState('');
  const [clave2, setClave2] = useState('');
  const [mailUsuario, setMailUsuario] = useState('');
  const [mailDominio, setMailDominio] = useState('');
  const [activarUsuario, setActivarUsuario] = useState(false);
  const [EmpresaABM, setEmpresaABM] = useState('default');
  const [rolABM, setRolABM] = useState('default');

  function manejarEmpresaABM(e) {
    e.preventDefault();
    setEmpresaABM(e.target.value);
  }

  function manejarRolABM(e) {
    e.preventDefault();
    setRolABM(e.target.value);
  }

  function manejarEnvioAlta() {
    if (nombre === '' || apellido === '' || clave === '' || clave2 === '' || mailUsuario === '' || mailDominio === '' || EmpresaABM === 'default' || rolABM === 'default') {
      Alerta.fire({
        icon: 'warning',
        title: 'Asegurese de completar todos los campos.'
      })
    } else {
      if (clave !== clave2) {
        Toast.fire({
          icon: 'warning',
          title: "Las claves ingresadas no coinciden."
        })
      } else {
        if(mailUsuario.includes('@') || mailDominio.includes('@')){
          Toast.fire({
            icon: 'warning',
            title: "No hace falta ingresar el caracter '@' en el mail. Por favor, eliminelo."
          })
        }else{
          setMostrarMensajeAlta(false);
          setMostrarPantallaABM(true);
          ABMAlta()
        }
      }
    }
  }

  async function ABMAlta() {
    cargandoset(true);

    let mail = mailUsuario.trim() + '@' + mailDominio.trim();
    let passw = encriptar(clave)
    try {
      let insertar = await miScript.UsuariosABMAlta(nombre, apellido, passw, mail, EmpresaABM, rolABM)
      if (!insertar.ERROR) {
        funcionCargarABM(enSesion);
        Toast.fire({
          icon: 'success',
          title: 'El usuario se registró correctamente'
        })
      } else {
        Alerta.fire({
          icon: 'error',
          title: insertar.MENSAJE
        })
      }
    } catch (err) {
      Alerta.fire({
        icon: 'error',
        title: 'Error en el proceso, intente nuevamente.'
      })
    }
    setEmpresaABM('default'); setRolABM('default'); setDefault(); setItemSeleccionado(null); 
    cargandoset(false);
  }

  function manejarModABM(item) {
    if (enSesion.MailUsuario === item.MailUsuario) {
      Alerta.fire({
        icon: "warning",
        title: "No puede modificar el usuario en sesión."
      })
    } else {
      setItemSeleccionado(item); setActivarUsuario(item.Estado); setMostrarPantallaABM(false); setMostrarMensajeMod(true)
    }
  }

  async function prepararABMmod(){
    if(mailDominio.length!== 0 && mailUsuario.length === 0){
      Alerta.fire({
        icon:'warning',
        text:'Asegúrese de que los campos del mail esten correctamente completados.'
      })
    }else{
      if(itemSeleccionado.Estado === true && activarUsuario === false){
        RecargaAlerta.fire({
          icon:'warning',
          title:'ATENCION: Se está por desactivar un usuario.',
          text: `Si continua se eliminarán las conexiones activas que posea ${itemSeleccionado.MailUsuario}.`
        }).then((result) => {
          if (result.isConfirmed) {
            setMostrarMensajeMod(false); setMostrarPantallaABM(true); ABMMod(itemSeleccionado);
          }
        });
      }else{
        setMostrarMensajeMod(false); setMostrarPantallaABM(true); ABMMod(itemSeleccionado);
      }
    }
  }

  async function ABMMod(item) {
    cargandoset(true);
    let passw = '';
    if (clave !== '') {
      passw = encriptar(clave);
    }
    let empresa = EmpresaABM;
    if (empresa === 'default') {
      empresa = '';
    }
    let rol = rolABM;
    if (rol === 'default') {
      rol = '';
    }
    let mail = '';
    if(mailDominio.length !== 0 && mailUsuario.length !== 0){
      mail = mailUsuario+"@"+mailDominio;
    }
    try {
      let modificacion = await miScript.UsuariosABMMod(item, mail, nombre, apellido, passw, empresa, rol, activarUsuario);
      if (!modificacion.ERROR) {
        funcionCargarABM(enSesion);
        Alerta.fire({
          icon: "success",
          title: "La modificación ha sido existosa."
        });
      } else {
        Alerta.fire({
          icon: "error",
          title: 'ERROR',
          text: modificacion.MENSAJE
        });
      }
    } catch (err) {
      Alerta.fire({
        icon: "error",
        title: 'Error',
        text: "Fallo al modificar, intente nuevamente."
      });
    }
    setDefault(); setItemSeleccionado(null);
    cargandoset(false);
  }

  async function ABMBaja(item) {
    try {
      let baja = await miScript.UsuariosABMBaja(item);
      if (baja) {
        funcionCargarABM();
        Alerta.fire({
          icon: "success",
          title: "La baja ha sido existosa."
        });
      } else {
        Alerta.fire({
          icon: "error",
          title: "Fallo al dar la baja, intente nuevamente."
        });
      }
    } catch {
      Alerta.fire({
        icon: "error",
        title: "Fallo al dar la baja, intente nuevamente."
      });
    }
  }

  function setDefault() {
    setNombre('');
    setApellido('');
    setClave('');
    setClave2('');
    setMailUsuario('');
    setMailDominio('');
    setEmpresaABM('default');
    setRolABM('default');
    setActivarUsuario(false);
    setConexionesSeleccionadas({});
    setConXDefAlta(-1);
  }

  return (
    <div className='contenedor-abm'>
      {mostrarMensajeAlta && (
        <div className="MensajeAlta MensajeAltaUsuactivo">
          <h3>Añadir Usuario</h3>
          <form className='form-group formulario-abm'>
            <div className='input-group mb-3'>
              <input type='text' placeholder='Nombre' className='form-control' name='nombre' onChange={(e) => setNombre(e.target.value)} />
            </div>
            <div className='input-group mb-3'>
              <input type='text' placeholder='Apellido' className='form-control' name='apellido' onChange={(e) => setApellido(e.target.value)} />
            </div>
            <div className='input-group mb-3'>
              <input type='password' placeholder='Clave' className='form-control' name='Clave' onChange={(e) => setClave(e.target.value)} />
            </div>
            <div className='input-group mb-3'>
              <input type='password' placeholder='Repita la clave' className='form-control' name='Clave2' onChange={(e) => setClave2(e.target.value)} />
            </div>
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Usuario" onChange={(e) => setMailUsuario(e.target.value)} />
              <span class="input-group-text">@</span>
              <input type="text" class="form-control" placeholder="Ejemplo.com" onChange={(e) => setMailDominio(e.target.value)} />
            </div>
            <select className='form-select form-select-sm mb-3' id='select-empresa-ABMAlta' value={EmpresaABM} onChange={manejarEmpresaABM} >
              <option value='default'>Seleccione la empresa para el usuario</option>
              {EMPRESASDISPONIBLES.map((empresa) => (
                <option key={empresa} value={empresa}>
                  {empresa}
                </option>
              ))}
            </select>
            <select className='form-select form-select-sm mb-3' id='select-rol-ABMAlta' value={rolABM} onChange={manejarRolABM} >
              <option value='default'>Seleccione el rol para el usuario</option>
              {rolesABM.map((rol) => (
                <option key={rol.idRol} value={rol.idRol}>
                  {rol.NombreRol}
                </option>
              ))}
            </select>
            <div className="Botones-Mensaje">
              <button type="button" className="btn btn-secondary" onClick={() => { setDefault(); setItemSeleccionado(null); setMostrarMensajeAlta(false); setMostrarPantallaABM(true) }}>Cancelar</button>
              <button type="button" className="btn btn-success" onClick={manejarEnvioAlta}>Confirmar</button>
            </div>
          </form>
        </div>
      )}

      {mostrarMensajeMod && (
        <div className="MensajeMod">
          <h3>Modificar Usuario</h3>
          <form className='form-group formulario-abm'>
            <div className='input-group mb-3'>
              <input type='text' placeholder={itemSeleccionado.NombreUsuario} className='form-control' name='nombre' onChange={(e) => setNombre(e.target.value.trim())} />
            </div>
            <div className='input-group mb-3'>
              <input type='text' placeholder={itemSeleccionado.ApellidoUsuario} className='form-control' name='apellido' onChange={(e) => setApellido(e.target.value.trim())} />
            </div>
            <div className='input-group mb-3'>
              <input type='text' placeholder='Clave' className='form-control' name='Clave' onChange={(e) => setClave(e.target.value.trim())} />
            </div>
            <div class="input-group mb-3" style={{width: 100+'%'}}>
              <span type="text" class="input-group-text" style={{width: 30+'%'}}>Mail Actual:</span>
              <span type="text" class="input-group-text text-truncate" style={{width: 70+'%'}}>{itemSeleccionado.MailUsuario}</span>
            </div>
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Usuario" onChange={(e) => setMailUsuario(e.target.value.trim())} />
              <span class="input-group-text">@</span>
              <input type="text" class="form-control" placeholder="Ejemplo.com" onChange={(e) => setMailDominio(e.target.value.trim())} />
            </div>
            <select className='form-select form-select-sm mb-3' id='select-empresa-ABMAlta' value={EmpresaABM} onChange={manejarEmpresaABM} >
              <option value='default'>Seleccione la empresa para el usuario</option>
              {EMPRESASDISPONIBLES.map((empresa) => (
                <option key={empresa} value={empresa}>
                  {empresa}
                </option>
              ))}
            </select>
            <select className='form-select form-select-sm mb-3' id='select-rol-ABMAlta' value={rolABM} onChange={manejarRolABM} >
              <option value='default'>Seleccione el rol para el usuario</option>
              {rolesABM.map((rol) => (
                <option key={rol.idRol} value={rol.idRol}>
                  {rol.NombreRol}
                </option>
              ))}
            </select>
            <div className="form-check form-switch mb-1">
              <input className="form-check-input" type="checkbox" role="switch" id="ActivarUsuario" value={activarUsuario} onChange={(e) => setActivarUsuario(e.target.checked)} checked={activarUsuario} />
              <label className="form-check-label" htmlFor="permRolesABMAlta">Estado:{(activarUsuario) ? ' Activo' : ' Desactivado'}</label>
            </div>
          </form>
          <div className="Botones-Mensaje">
            <button type="button" class="btn btn-secondary" onClick={() => { setDefault(); setItemSeleccionado(null); setItemSeleccionado(null); setMostrarMensajeMod(false); setMostrarPantallaABM(true) }}>Cancelar</button>
            <button type="button" class="btn btn-primary" onClick={() => { (mailUsuario.includes('@') || mailDominio.includes('@'))?Alerta.fire({icon:"warning", title:'No se necesita el caracter "@" en el mail. Borrelo para continuar.'}): prepararABMmod()}}>Confirmar</button>
          </div>
        </div>)}

      {mostrarMensajeBaja && (<div className="MensajeBaja">
        <p>¿Borrar usuario <strong>{itemSeleccionado.NombreUsuario + ' ' + itemSeleccionado.ApellidoUsuario}</strong>?</p>
        <div className="Botones-Mensaje">
          <button type="button" class="btn btn-secondary" onClick={() => { setItemSeleccionado(null); setMostrarMensajeBaja(false) }}>Cancelar</button>
          <button type="button" class="btn btn-danger" onClick={
            (itemSeleccionado.idUsuario === enSesion.idUsuario)?
            () =>{Alerta.fire({icon:'error',title:'No se puede eliminar el usuario en sesión.'}); setMostrarMensajeBaja(false);}
            :
            ()=>{ ABMBaja(itemSeleccionado); setMostrarMensajeBaja(false); }}//acomodar para que borre todas las conexiones activas, que muestre un mensaje de que las tiene
          >Confirmar</button>
        </div>
      </div>)}

      {USUARIOCONEXIONESAlta && (
        <>
          <div className="AltaUsuarioConexion">
            <h3 className="text-center">Conexiónes para el usuario <br/> <strong>{itemSeleccionado.MailUsuario}</strong></h3>
            <form className='form-group' style={{width:90+'%'}}>
              <table className="table table-striped align-middle table-bordered">
                <thead>
                  <tr >
                    <th className='text-center' scope='col'>Nombre</th>
                    <th className='text-center' scope='col'>Servidor</th>
                    <th className='text-center' scope='col'>Base</th>
                    <th className='text-center' scope='col'>Empresa</th>
                    <th className='text-center' scope='col'>Activa</th>
                    <th className='text-center' scope='col'>Por defecto</th>
                  </tr>
                </thead>
                <tbody>
                {conexionesABM.map((con, index)=>(
                  <tr key={index}>
                    <td className='text-center'>{con.Nombre}</td>
                    <td className='text-center'>{con.Servidor}</td>
                    <td className='text-center'>{con.BaseDatos}</td>
                    <td className='text-center'>{con.EmpresaUsuario}</td>
                    <td className="text-center" onClick={() => manejarSeleccionConexion(con)}>
                      <input
                              className="form-check-input"
                              type='checkbox'
                              id={con.idConexion}
                              checked={conexionesSeleccionadas[con.idConexion]?.seleccionado}
                              readOnly
                              /* onChange={() => manejarSeleccionConexion(con)} */
                            />
                    </td>
                    <td className="text-center" onClick={()=>{(con.idConexion === ConXDefAlta)?setConXDefAlta(-1):setConXDefAlta(con.idConexion); }}>
                      <input 
                        className="form-check-input"
                        type='checkbox'
                        id={con.idConexion}
                        checked={(con.idConexion === ConXDefAlta)?true:false}
                        readOnly
                        /* onChange={()=>{(con.idConexion === ConXDefAlta)?setConXDefAlta(-1):setConXDefAlta(con.idConexion); }} */ />
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </form>
            <div className="Botones-Mensaje">
              <button type="button" className="btn btn-secondary" onClick={() => { setDefault(); setUSUARIOCONEXIONES(true); setUSUARIOCONEXIONESAlta(false) }}>Cancelar</button>
              <button type="button" className="btn btn-success" onClick={manejarAltaUSUARIOCONEXIONES}>Confirmar</button>
            </div>
          </div>
        </>
      )}

      {USUARIOCONEXIONES && (
        <>
          <div className='contenido-contenedor-abm contenedor-abm-RolesYPermisos'>
            <div className="Boton-VolverAUsuarios">
              <button type="button" className="btn btn-secondary" 
                onClick={() => { setDefault(); setItemSeleccionado(null); funcionUSUARIO_CONEXIONES(-1); setUSUARIOCONEXIONES(false); setMostrarPantallaABM(true) }}>
                Volver a Usuarios
              </button>
              {/*Alta y modificacion*/}
              <button className="btn btn-primary"
                onClick={async () => {await prepararUSUARIOCONEXIONESAlta(); setUSUARIOCONEXIONES(false); setUSUARIOCONEXIONESAlta(true)}}>   
                Editar conexiónes del usuario
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5" />
                  <path fill-rule="evenodd" d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z" />
                </svg>
              </button>
            </div>
            <br/>
            <div className='titulo-contenido-contenedor-abm-usuarioconexiones'>
              <h3 className="text-wrap text-center">Conexiones activas de <br/><strong>{itemSeleccionado.MailUsuario}</strong></h3>
              
            </div>
            <br/>
            <table className="table align-middle table-bordered table-hover tabla-abm-RolesYPermisos">
              <thead>
                <tr >
                        <th className='text-center' scope='col'>Nombre conexión</th>
                        <th className='text-center' scope='col'>Empresa</th>
                        <th className='text-center' scope='col'>Servidor</th>
                        <th className='text-center' scope='col'>Base</th>
                        <th className='text-center' scope='col'>Por defecto</th>
                </tr>
              </thead>
              <tbody>
                {dataUsuario_Conexiones.map((row, index) => (
                  <tr key={index}>
                    <td className='text-center text-truncate'>{row.nombre}</td>
                    <td className='text-center'>{row.empresa}</td>
                    <td className='text-center'>{row.servidor}</td>
                    <td className='text-center'>{row.base}</td>
                    <td className='text-center'>{(row.XDef) ? 'Si' : 'No'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {mostrarPantallaABM && (
        <>
          <div className='contenido-contenedor-abm contenedor-abm-RolesYPermisos'>
            <div className="Boton-VolverAUsuarios">
              <button className="btn btn-secondary" onClick={VolverHome}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"/>
                  <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"/>
                </svg>
                {' '}Ir a Pantalla Principal</button>
              <button className="btn btn-success"
                onClick={async () => {await cargarRoles(); setMostrarMensajeAlta(true); setMostrarPantallaABM(false); }}>   {/*Alta*/}
                Añadir usuario{' '}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
                  <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                  <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
                </svg>
              </button>
            </div>
            <br/>
            <div className='titulo-contenido-contenedor-abm'>
              <h3><strong>Usuarios</strong></h3>
              
            </div>
            <table className="table align-middle table-bordered table-hover tabla-abm-RolesYPermisos">
              <thead>
                <tr >
                  <th className='text-center' scope='col'>Mail</th>
                  <th className='text-center' scope='col'>Activo</th>
                  <th className='text-center' scope='col'>Apellido y Nombre</th>
                  <th className='text-center' scope='col'>Empresa</th>
                  <th className='text-center' scope='col'>Rol</th>
                  <th className='text-center' scope='col'>Acciones</th>
                  <th className='text-center' scope='col'>Conexiones activas</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td className='text-center'>{row.MailUsuario}</td>
                    <td className='text-center'>{row.Estado ? 'Si' : 'No'}</td>
                    <td className='text-center'>{`${row.ApellidoUsuario} ${row.NombreUsuario}`}</td>
                    <td className='text-center'>{row.EmpresaUsuario}</td>
                    <td className='text-center text-truncate'>{row.Rol}</td>
                    <td className="text-center acciones-tabla-abm">
                      <button className="btn btn-warning" style={{ width: 100 + '%', marginRight: 2 + '%' }} 
                      onClick={async () => { await cargarRoles(); manejarModABM(row) }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                        </svg>{' '}Editar
                      </button>
                    </td>

                    {/*boton de conexiones del usuario*/}

                    {(row.Estado)?<><th className='text-center conexiones-usuarios-abm' >
                      <button className="btn btn-outline-info" style={{ width: 100 + '%' }}
                      onClick={async () => {setDefault(); setItemSeleccionado(null); setItemSeleccionado(row); await funcionUSUARIO_CONEXIONES(row.idUsuario); setMostrarPantallaABM(false); setUSUARIOCONEXIONES(true) }}>
                        Abrir{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5" />
                          <path fill-rule="evenodd" d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z" />
                        </svg>
                      </button>
                    </th></>
                    :
                    <><th className='text-center conexiones-usuarios-abm' >
                        <button className="btn btn-outline-dark" style={{ width: 100 + '%' }} onClick={()=>{Toast.fire({icon: "info", text:'Para utilizar esta función el usuario seleccionado debe estar activo.'})}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2"/>
                          </svg>
                        </button>
                      </th></>}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  )
}

export default UsuariosABM;