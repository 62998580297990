//APP.js

import { useState } from 'react';
import './App.css';
import * as XLSX from 'xlsx';
import moment from 'moment';
import miScript from './conexion.js';
import PantallaHome from './PantallaHome.js';
import LogoBarra from './LogoBarra.js';
import FilaResultado from './FilaResultado.js';
import ConexionesABM from './ABM/ConexionesABM.js';
import EmpresasABM from './ABM/EmpresasABM.js';
import RolesABM from './ABM/RolesABM.js';
import UsuariosABM from './ABM/UsuariosABM.js';
import Swal from 'sweetalert2';
const CryptoJS = require('crypto-js');

function App() {

  //alertas
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  const Alerta = Swal.mixin({
    toast: false,
    position: "center",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  //menu y barra lateral
  const [menuSeleccionado, setMenuSeleccionado] = useState('Home');
  const [mostrarHome, setMostrarHome] = useState(false);
  const [usarBarraLateral, setUsarBarraLateral] = useState(true);
  const [conexionElegida, setConexionElegida] = useState(false);

  //empresa
  const [empresasDisponibles, setEmpresasDisponibles] = useState([]);
  const [listaEmpresasParaABM, setListaEmpresaParaABM] = useState(null);

  const cargarEmpresas = async () => {
    let empresas;
    if (usuarioEnSesion.Rol === 'ADMIN') {
      empresas = await miScript.listarEmpresas();
      if (empresas.ERROR) {//si hay un error carga la empresa del usuario en sesion
        Alerta.fire({
          icon: 'error',
          title: empresas.MENSAJE
        })
        empresas = [usuarioEnSesion.Empresa]
      } else {
        empresas = empresas.CONTENIDO.map(obj => obj.NOMBRE_EMP)
      }
    } else {
      empresas = [usuarioEnSesion.Empresa]
    }
    setEmpresasDisponibles(empresas);
    return true
  };

  const cargarTodasEmpresasConDatos = async () => {
    setCargando(true);
    const empresas = await miScript.listarTodasEmpresasConDatos();

    if (empresas.ERROR) {
      Alerta.fire({
        icon: 'error',
        title: empresas.MENSAJE
      })
    }
    setListaEmpresaParaABM(empresas.CONTENIDO);
    setCargando(false);
  }

  //conexion
  const [conexionesActivasHome, setConexionesActivasHome] = useState([]);

  const [conexionesABM, setConexionesABM] = useState([])


  const cargarUSUARIO_CONEXIONHome = async (usuario) => {
    const conexiones = await miScript.listarUSUARIO_CONEXION(usuario);
    if (conexiones.ERROR) {
      Toast.fire({
        icon: "error",
        title: conexiones.MENSAJE
      });
    }
    else {
      if (conexiones.CONTENIDO.length !== 0) {
        let array = conexiones.CONTENIDO;
        let nuevoArray = [];
        array.forEach((fila) => {
          let nuevo = {
            idConexion: fila.ID_CONEXIONES,
            nombre: fila.NOMBRE_CONEXION,
            servidor: fila.SERVIDOR,
            base: fila.BASE_DATOS,
            XDef: fila.CONEXION_POR_DEFECTO,
            idUsuario: fila.ID_USUARIO,
            idEmpresa: fila.EMPRESAID,
            empresa: fila.NOMBRE_EMP,
            LogPrin: fila.LOGOPRINCIPAL,
            LogMin: fila.LOGOMINIATURA
          }
          nuevoArray.push(nuevo);
        });
        setConexionesActivasHome(nuevoArray);
      }
    }
  };

  const [USUARIO_CONEXIOBESABM, setUSUARIO_CONEXIOBESABM] = useState([]);
  const cargarUSUARIO_CONEXIONABM = async (usuario) => {
    const conexiones = await miScript.listarUSUARIO_CONEXION(usuario);
    if (conexiones.ERROR) {
      Toast.fire({
        icon: "error",
        title: conexiones.MENSAJE
      });
      setUSUARIO_CONEXIOBESABM([]);
    }
    else {
      if (conexiones.CONTENIDO.length !== 0) {
        let array = conexiones.CONTENIDO;
        let nuevoArray = [];
        array.forEach((fila) => {
          let nuevo = {
            idConexion: fila.ID_CONEXIONES,
            nombre: fila.NOMBRE_CONEXION,
            servidor: fila.SERVIDOR,
            base: fila.BASE_DATOS,
            XDef: fila.CONEXION_POR_DEFECTO,
            idUsuario: fila.ID_USUARIO,
            idEmpresa: fila.EMPRESAID,
            empresa: fila.NOMBRE_EMP,
            LogPrin: fila.LOGOPRINCIPAL,
            LogMin: fila.LOGOMINIATURA
          }
          nuevoArray.push(nuevo);
        });
        setUSUARIO_CONEXIOBESABM(nuevoArray);
      }else{
        setUSUARIO_CONEXIOBESABM([]);
      }
    }
  };

  async function cambiarConexion(fila) {
    setUsuarioEnSesion({
      ...usuarioEnSesion,
      NombreConexion: fila.nombre,
      idConexiones: fila.idConexion,
      Servidor: fila.servidor,
      Base: fila.base,
      idEmpresa: fila.idEmpresa,
      Empresa: fila.empresa,
      LogoPrincipal: fila.LogPrin,
      LogoMiniatura: fila.LogMin
    })
  }

  function mostrarCambioConexion(nombre) {
    Toast.fire({
      icon: 'info',
      title: 'Cambio a la conexión: ' + nombre
    })
  }


  async function cargarConexionesABM(usu) {
    setCargando(true);
    let conexionesBuscadas;
    if (usu.Rol === 'ADMIN') {
      conexionesBuscadas = await miScript.cargarConexionesABM();
    } else {
      conexionesBuscadas = await miScript.cargarConexionesABMNOADMIN(usu.Empresa);
    }
    if (conexionesBuscadas.ERROR) {
      Toast.fire({
        icon: "warning",
        title: conexionesBuscadas.MENSAJE
      });
    } else {
      if (conexionesBuscadas.CONTENIDO.length === 0) {
        Toast.fire({
          icon: "warning",
          title: "No hay conexiones registradas"
        });
        setConexionesABM([]);
      } else {
        let i = 0; let conexionesActuales = [];

        while (i < conexionesBuscadas.CONTENIDO.length) {
          let nuevo = {
            idConexion: conexionesBuscadas.CONTENIDO[i].ID_CONEXIONES,
            Nombre: conexionesBuscadas.CONTENIDO[i].NOMBRE_CONEXION,
            Servidor: conexionesBuscadas.CONTENIDO[i].SERVIDOR,
            BaseDatos: conexionesBuscadas.CONTENIDO[i].BASE_DATOS,
            Activo: conexionesBuscadas.CONTENIDO[i].ACTIVO,
            idEmpresa: conexionesBuscadas.CONTENIDO[i].EMPRESAID,
            Empresa: conexionesBuscadas.CONTENIDO[i].NOMBRE_EMP
          }
          conexionesActuales.push(nuevo);
          i += 1;
        }
        setConexionesABM(conexionesActuales);
      }
    }
    setCargando(false);
  };

  const [configSBABM, setConfigSABM] = useState([]);

  async function cargarConfigSBABM(idConexion) {
    try{
      let configuracionesBuscadas = await miScript.cargarConfigSBABM(idConexion);
      if (configuracionesBuscadas.ERROR) {
        Toast.fire({
          icon: "error",
          title: configuracionesBuscadas.MENSAJE
        });
        setConfigSABM([]);
        return []
      } 
      else {
        setConfigSABM(configuracionesBuscadas.CONTENIDO);
        return configuracionesBuscadas.CONTENIDO
      }
    }catch{
      Toast.fire({
        icon: "error",
        title: 'Error en la conexión.'
      });
      setConfigSABM([]);
      return []
    }
  }

  async function manejarSeleccionCSB(id) {
    if (id === 'default') {
      setConfiguracionSBSeleccionada({idConfig:'default'});
    } else {
      let ID = Number(id);
      for (let config of configuracionesSBDisponibles) {
        if (config.idConfig === ID) {
          console.log('encontro')
          setConfiguracionSBSeleccionada(config);
          break;
        }
      }
    }
    return true
  }


  //Roles

  const [rolesABM, setRolesABM] = useState([]);

  async function cargarRolesABM() {
    setCargando(true);
    const rolesBuscados = await miScript.cargarRolesABM();
    if (rolesBuscados.ERROR) {
      Toast.fire({
        icon: "warning",
        title: rolesBuscados.MENSAJE
      });
    } else {
      setRolesABM(rolesBuscados);
    }
    setCargando(false);
  }

  const [usuariosABM, setUsuariosABM] = useState([]);
  const [usuarioEnSesion, setUsuarioEnSesion] = useState({
    LogoMiniatura: './logos/LogoVacio.png',
    LogoPrincipal: './logos/LogoVacio.png'
  });
  const [userLogin, setUserLogin] = useState('');
  const [passwLogin, setPasswLogin] = useState('');

  function encriptar(password) {
    const hash = CryptoJS.SHA256(password);
    const textoCifrado = hash.toString(CryptoJS.enc.Hex);
    return textoCifrado;
  }

  async function manejarLogin(e) {
    e.preventDefault()
    if (userLogin === '' || passwLogin === '') {
      Toast.fire({
        icon: "warning",
        title: 'Complete los campos "Usuario"\ny "Contraseña"'
      })
    } else {
      let passw = encriptar(passwLogin)
      try {
        let login = await miScript.login(userLogin, passw);
        if (login.ERROR) {
          Alerta.fire({
            icon: 'error',
            title: 'Error: ' + login.MENSAJE
          })
        } else {
          if (login.Usuario) {
            if (login.Clave) {
              let datos = login.DATA;
              let datosUsuario = {
                idUsuario: datos.ID_USUARIO,
                MailUsuario: datos.MAIL,
                NombreUsuario: datos.NOMBRE,
                ApellidoUsuario: datos.APELLIDO,
                FecMod: datos.FECHA_MOD,
                idRol: datos.ID_ROL,
                Rol: datos.ROL_PARTICULAR,
                idEmpresaUsuario: datos.ID_EMPRESA,
                EmpresaUsuario: datos.NOMBRE_EMP,
                LogoPrincipal: login.NOMBREEMPRESACONEXION.LOGOPRINCIPAL,
                LogoMiniatura: login.NOMBREEMPRESACONEXION.LOGOMINIATURA,
                idPermiso: datos.ID_PERMISO,
                AgrCon: datos.RRHH_AGREGAR_CONCEPTOS,
                ElimCon: datos.RRHH_ELIMINAR_CONCEPTOS,
                ActSueldo: datos.RRHH_ACTUALIZACION_SUELDOB,
                TabEmp: datos.TABLAS_EMPRESAS,
                TabConex: datos.TABLAS_CONEXIONES,
                TabConfig: datos.TABLAS_CONFIG_SUELDOB,
                TabRoles: datos.TABLAS_ROLES,
                TabUsuarios: datos.TABLAS_USUARIOS,
                idConexiones: datos.ID_CONEXIONES,
                NombreConexion: datos.NOMBRE_CONEXION,
                Servidor: datos.SERVIDOR,
                Base: datos.BASE_DATOS,
                idEmpresa: login.NOMBREEMPRESACONEXION.ID_EMPRESAS,//DE LA CONEXION
                Empresa: login.NOMBREEMPRESACONEXION.NOMBRE_EMP,//DE LA CONEXION
              }
              setUsuarioEnSesion(datosUsuario);
              setMostrarHome(!mostrarHome);
              setConexionElegida(true);
              setUsarBarraLateral(false);
              cargarUSUARIO_CONEXIONHome(datos.ID_USUARIO);
              setUsuariosABM(datosUsuario);
              Toast.fire({
                icon: 'success',
                title: `Sesión iniciada: ${datosUsuario.NombreUsuario} ${datosUsuario.ApellidoUsuario}.`
              })
            } else {
              Toast.fire({
                icon: 'error',
                title: 'Contraseña incorrecta.'
              })
            }
          } else {
            Toast.fire({
              icon: 'error',
              title: 'Usuario incorrecto.'
            })
          }
        }
      } catch (err) {
        Alerta.fire({
          icon: 'error',
          title: 'Error inesperado. Recargue la página e intente nuevamente.'
        })
      }
    }
  }

  async function cargarUsuariosABM(usuSesion) {
    setCargando(true);
    const usuariosBuscados = await miScript.cargarUsuariosABM(usuSesion.Rol, usuSesion.idEmpresa);
    if (usuariosBuscados.ERROR) {
      Toast.fire({
        icon: "warning",
        title: usuariosBuscados.MENSAJE
      });
      let nuevo = {
        idUsuario: usuSesion.idUsuario,
        MailUsuario: usuSesion.MailUsuario,
        NombreUsuario: usuSesion.NombreUsuario,
        ApellidoUsuario: usuSesion.ApellidoUsuario,
        FecMod: usuSesion.FecMod,
        Estado: true,
        idRol: usuSesion.idRol,
        Rol: usuSesion.Rol,
        ActSueldo: usuSesion.ActSueldo,
        idEmpresaUsuario: usuSesion.idEmpresaUsuario,
        EmpresaUsuario: usuSesion.EmpresaUsuario
      }
      setUsuariosABM([nuevo])
    } else {
      setUsuariosABM(usuariosBuscados.CONTENIDO);
    }
    setCargando(false);
  }

  //--------------------------------------------------------------------------------

  const [opcion, setOpcion] = useState('');
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [mostrarSeleccionConfig, setMostrarSeleccionConfig] = useState(true);
  const [configuracionesSBDisponibles, setConfiguracionesSBDisponibles] = useState([]);
  const [configuracionSBSeleccionada, setConfiguracionSBSeleccionada] = useState({idConfig:'default'});
  const [mostrarTabla, setMostrarTabla] = useState(true);
  const [habilitarAplicarCambios, setHablitarAplicarCambios] = useState(false);
  const [mostrarResultado, setMostrarResultado] = useState(true);
  const [cargando, setCargando] = useState(false);
  const [rows, setRows] = useState([]);
  const [file, setFile] = useState();
  const [resultadosExitosos, setResultadosExitosos] = useState([]);
  const [resultadosErroneos, setResultadosErroneos] = useState([]);
  const [resultadosNoProcesados, setResultadosNoProcesados] = useState([]);
  const [resultadosEliminacionExitosos, setResultadosEliminacionExitosos] = useState([]);
  const [resultadosEliminacionErroneos, setResultadosEliminacionErroneos] = useState([]);
  const [resultadosEliminacionNoProcesados, setResultadosEliminacionNoProcesados] = useState([]);

  const cambiarMenu = (eleccion) => {
    setMenuSeleccionado(eleccion);/* 
    console.log("Eligio menu: "+eleccion); */
  };

  const cambiarOpcion = (eleccion) => {
    setOpcion(eleccion);/* 
    console.log('Elegio opcion: '+eleccion); */
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  }


  //actualizacion de sueldo basico
  async function cargarExcelSueldos(event) {
    event.preventDefault();
    setUsarBarraLateral(true);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.error) {
        Alerta.fire({
          icon: "error",
          title: "Error al cargar el archivo.\nPor favor, intente nuevamente."
        });
        /* alert("Error al cargar el archivo.\nPor favor, intente nuevamente."); */
        setFile("");
        const inputFile = document.getElementById("formFileLg");
        inputFile.value = "";
        setMostrarFormulario(false);
        setCargando(false);
        return -1;
      }

      const bstr = reader.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let data = XLSX.utils.sheet_to_json(ws, { raw: false });

      data = data.map(row => {
        const legajo = row.Legajo ? row.Legajo : '';
        const apellido = row.Apellidos ? row.Apellidos : '';
        const nombre = row.Nombre ? row.Nombre : '';
        const SB = row["Sueldo Básico"] ? row["Sueldo Básico"] : '';
        return { Legajo: legajo, Apellidos: apellido, Nombre: nombre, ["Sueldo Básico"]: SB };
      });

      setRows(data);
      setCargando(false);
      setUsarBarraLateral(false);
      setMostrarTabla(!mostrarTabla); // Mover esta línea aquí
    };

    setMostrarFormulario(!mostrarFormulario);
    setCargando(true);
    reader.readAsBinaryString(file);
    let input = document.getElementById('formFileLg');
    input.value = '';
  }

  //añadir y borrar conceptos de liquidacion
  async function cargarArchivoExcel(event) {
    event.preventDefault();
    setUsarBarraLateral(true);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.error) {
        Alerta.fire({
          icon: "error",
          title: "Error al cargar el archivo.\nPor favor, intente nuevamente."
        });
        /* alert("Error al cargar el archivo.\nPor favor, intente nuevamente."); */
        setFile("");
        const inputFile = document.getElementById("formFileLg");
        inputFile.value = "";
        setMostrarFormulario(false);
        setCargando(false);
        return -1;
      }

      const bstr = reader.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let data = XLSX.utils.sheet_to_json(ws, { raw: false });

      data = data.map(row => {
        const legajo = row.Legajo ? row.Legajo : '';
        const apellido = row.Apellidos ? row.Apellidos : '';
        const nombre = row.Nombre ? row.Nombre : '';
        const nro = row["Nro. de concepto"] ? row["Nro. de concepto"] : '';
        const concepto = row.Concepto ? row.Concepto : '';
        const desde = row.Desde ? moment(row.Desde, 'MM/DD/YY').format('DD-MM-YYYY') : '';
        const hasta = row.Hasta ? moment(row.Hasta, 'MM/DD/YY').format('DD-MM-YYYY') : '';
        return { Legajo: legajo, Apellidos: apellido, Nombre: nombre, ["Nro. de concepto"]: nro, Concepto: concepto, Desde: desde, Hasta: hasta };
      });

      setRows(data);
      setCargando(false);
      setUsarBarraLateral(false);
      setMostrarTabla(!mostrarTabla); // Mover esta línea aquí
    };

    setMostrarFormulario(!mostrarFormulario);
    setCargando(true);
    reader.readAsBinaryString(file);
    let input = document.getElementById('formFileLg');
    input.value = '';
  }

  async function enviarALaBase() {
    setUsarBarraLateral(true);
    setMostrarTabla(true);
    setCargando(true);
    setHablitarAplicarCambios(!habilitarAplicarCambios);
    let base = usuarioEnSesion.Base; let servidor = usuarioEnSesion.Servidor; let usuario = usuarioEnSesion.idUsuario;
    let empresa = usuarioEnSesion.idEmpresa; let conexion = usuarioEnSesion.idConexiones;
    let fechaActual = miScript.fechaActual();
    const [datosCorrectos, datosIncorrectos, datosNoIngresados] = await miScript.enviarDatos(rows, base, servidor, usuario, empresa, conexion, fechaActual);
    if (datosCorrectos.length !== 0) {
      setResultadosExitosos(datosCorrectos);
    }
    if (datosIncorrectos.length !== 0) {
      setResultadosErroneos(datosIncorrectos);
    }
    if (datosNoIngresados.length !== 0) {
      setResultadosNoProcesados(datosNoIngresados);
    }
    setCargando(false);
    setUsarBarraLateral(false);
    setMostrarResultado(false);
  };

  async function enviarALaBaseBORRAR() {
    setUsarBarraLateral(true);
    setMostrarTabla(true);
    setCargando(true);
    setHablitarAplicarCambios(!habilitarAplicarCambios);
    let base = usuarioEnSesion.Base; let servidor = usuarioEnSesion.Servidor; let usuario = usuarioEnSesion.idUsuario;
    let empresa = usuarioEnSesion.idEmpresa; let conexion = usuarioEnSesion.idConexiones;
    let fechaActual = miScript.fechaActual();
    const [datosEliminadosOk, datosNoEliminados, datosNoIngresados] = await miScript.enviarDatosBORRAR(rows, base, servidor, usuario, empresa, conexion, fechaActual);
    if (datosEliminadosOk.length !== 0) {
      setResultadosEliminacionExitosos(datosEliminadosOk);
    }
    if (datosNoEliminados.length !== 0) {
      setResultadosEliminacionErroneos(datosNoEliminados);
    }
    if (datosNoIngresados.length !== 0) {
      setResultadosEliminacionNoProcesados(datosNoIngresados);
    }
    setCargando(false);
    setUsarBarraLateral(false);
    setMostrarResultado(false);
  }

  async function buscarConfiguracion(conexion) {
    let configuraciones = await cargarConfigSBABM(usuarioEnSesion.idConexiones);
    let ConfigSu = [];
    for (let config of configuraciones) {
      if (config.idConexiones+'' === conexion+'') {
        ConfigSu.push(config);
      }
    }
    return ConfigSu;
  }

  async function enviarActualizacionSueldos() {
    setUsarBarraLateral(true);
    setMostrarTabla(true);
    setCargando(true);
    setHablitarAplicarCambios(!habilitarAplicarCambios);
    let base = usuarioEnSesion.Base; let servidor = usuarioEnSesion.Servidor;
    const resultado = await miScript.enviarSueldos(rows, configuracionSBSeleccionada, base, servidor, usuarioEnSesion);
    if (resultado.Error) {
      Alerta.fire({
        icon: 'error',
        title: resultado.Mensaje
      })
    } else {
      if (resultado.CORRECTOS.length !== 0) {
        setResultadosExitosos(resultado.CORRECTOS);
      }
      if (resultado.INCORRECTOS.length !== 0) {
        setResultadosErroneos(resultado.INCORRECTOS);
      }
      if (resultado.NOINGRESADOS.length !== 0) {
        setResultadosNoProcesados(resultado.NOINGRESADOS);
      }
      Toast.fire({
        icon: 'success',
        title: resultado.Mensaje
      })
    }
    setCargando(false);
    setUsarBarraLateral(false);
    setMostrarResultado(false);
  };

  function generarExcel(array, nombreArray) {
    if (!Array.isArray(array) || array.length === 0) {
      return;
    }
    else {
      const encabezados = array[0].Error ? ['Legajo', 'Apellidos', 'Nombre', 'Nro. de concepto', 'Concepto', 'Desde', 'Hasta', 'Detalle de error'] : ['Legajo', 'Apellidos', 'Nombre', 'Nro. de concepto', 'Concepto', 'Desde', 'Hasta'];

      if (array[0].FechaMod) {
        encabezados.push('Fecha de Modificación (AAAA-MM-DD HH:MM:SS)')
      }

      const arrayParaExcel = array.map(item => encabezados.map(header => {
        if (header === 'Detalle de error') {
          header = 'Error';
        }
        if (header === 'Fecha de Modificación (AAAA-MM-DD HH:MM:SS)') {
          header = 'FechaMod'
        }
        return item[header] || '';
      }));
      arrayParaExcel.unshift(encabezados);

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(arrayParaExcel);
      XLSX.utils.book_append_sheet(wb, ws, "Hoja1");
      XLSX.writeFile(wb, nombreArray + ' ' + usuarioEnSesion.Empresa + ' ' + usuarioEnSesion.NombreConexion + ' ' + usuarioEnSesion.MailUsuario + ' ' + miScript.fechaActual() + ".xlsx");
    }
  };

  function generarExcelSueldos(array, nombreArray) {
    if (!Array.isArray(array) || array.length === 0) {
      return;
    }
    else {
      const encabezados = array[0].Error ? ['Legajo', 'Apellidos', 'Nombre', "Sueldo Básico", 'Detalle de error'] : ['Legajo', 'Apellidos', 'Nombre', "Sueldo Básico"];

      const arrayParaExcel = array.map(item => encabezados.map(header => {
        if (header === 'Detalle de error') {
          header = 'Error';
        }
        if (header === 'Fecha de Modificación (AAAA-MM-DD HH:MM:SS)') {
          header = 'FechaMod'
        }
        return item[header] || '';
      }));
      arrayParaExcel.unshift(encabezados);

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(arrayParaExcel);
      XLSX.utils.book_append_sheet(wb, ws, "Hoja1");
      XLSX.writeFile(wb, nombreArray + ' ' + usuarioEnSesion.Empresa + ' ' + usuarioEnSesion.NombreConexion + ' ' + miScript.fechaActual() + ".xlsx");
    }
  }

  function IrArriba() {
    window.scrollTo(0, 0);
  }

  function manejarSeleccionActualizacionSueldo(){
    if(configuracionSBSeleccionada.idConfig === 'default'){
      Alerta.fire({
        icon:'warning',
        title:'Atencion!',
        text:'Para continuar seleccione una de las opciones.'
      }) 
    }
    else{
      setMostrarSeleccionConfig(true);
      setMostrarFormulario(false);}
  }


  const contenidoPagina = () => {
    switch (menuSeleccionado) {
      case 'Tablas':
        switch (opcion) {
          case 'Empresas':
            return <>
              {cargando && (<div className='contenedor-cargando'>
                <div className="spinner-border text-light" style={{ width: 3 + "rem", height: 3 + "rem" }} role="status">
                  <span className="visually-hidden">Cargando...</span>
                </div>
                <div>
                  <p>Este proceso puede tardar algunos minutos...</p>
                </div>
              </div>)}
              {!cargando && (
                <EmpresasABM
                  data={listaEmpresasParaABM}
                  FuncionCargarEmpresasConDatos={cargarTodasEmpresasConDatos}
                  EMPRESASELECCIONADA={usuarioEnSesion.Empresa}
                  cargandoset={setCargando} 
                  VolverHome={() => { seteardefault(); cambiarMenu('Home'); cargarUSUARIO_CONEXIONHome(usuarioEnSesion.idUsuario) }}/>)}
            </>
          case 'Conexiones':
            return (
              <>
                {cargando && <div className='contenedor-cargando'>
                  <div className="spinner-border text-light" style={{ width: 3 + "rem", height: 3 + "rem" }} role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </div>
                  <div>
                    <p>Este proceso puede tardar algunos minutos...</p>
                  </div>
                </div>}
                {!cargando && (
                  <ConexionesABM
                    data={conexionesABM}
                    conexSelec={usuarioEnSesion.idConexiones}
                    cargandoset={setCargando}
                    conexionesABMset={setConexionesABM}
                    EMPRESASDISPONIBLES={empresasDisponibles}
                    FuncionCargarEmpresas={cargarEmpresas}
                    FuncionCargarABM={cargarConexionesABM} 
                    dataConfiguraciones={configSBABM}
                    funcionCargarConfiguraciones={cargarConfigSBABM}
                    enSesion={usuarioEnSesion}
                    VolverHome={() => { seteardefault(); cambiarMenu('Home'); cargarUSUARIO_CONEXIONHome(usuarioEnSesion.idUsuario) }}/>)}
              </>
            );
          
            /* case 'ConfigSueldoBasico':
            return (
              <>
                {cargando && <div className='contenedor-cargando'>
                  <div className="spinner-border text-light" style={{ width: 3 + "rem", height: 3 + "rem" }} role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </div>
                  <div>
                    <p>Este proceso puede tardar algunos minutos...</p>
                  </div>
                </div>}
                {!cargando && (
                  <ConfigSueldoBasicoABM
                    dataConfiguraciones={configSBABM}
                    funcionCargarConfiguraciones={cargarConfigSBABM}
                    cargandoset={setCargando}
                    EMPRESASDISPONIBLES={empresasDisponibles}
                    FuncionCargarEmpresas={cargarEmpresas}
                    CONEXIONESDISPONIBLES={conexionesABM}
                    FuncionCargarConexiones={cargarConexionesABM} 
                    enSesion={usuarioEnSesion}
                    VolverHome={() => { seteardefault(); cambiarMenu('Home'); cargarUSUARIO_CONEXIONHome(usuarioEnSesion.idUsuario) }}/>)}
              </>
            ); */

          case 'Roles':
            return (<>
              {cargando && <div className='contenedor-cargando'>
                <div className="spinner-border text-light" style={{ width: 3 + "rem", height: 3 + "rem" }} role="status">
                  <span className="visually-hidden">Cargando...</span>
                </div>
                <div>
                  <p>Este proceso puede tardar algunos minutos...</p>
                </div>
              </div>}
              {!cargando && (
                <RolesABM
                  data={rolesABM}
                  cargandoset={setCargando}
                  funcionCargarABM={cargarRolesABM} 
                  VolverHome={() => { seteardefault(); cambiarMenu('Home'); cargarUSUARIO_CONEXIONHome(usuarioEnSesion.idUsuario) }}/>)}
            </>
            )
          case 'Usuarios':
            return (<>
              {cargando && <div className='contenedor-cargando'>
                <div className="spinner-border text-light" style={{ width: 3 + "rem", height: 3 + "rem" }} role="status">
                  <span className="visually-hidden">Cargando...</span>
                </div>
                <div>
                  <p>Este proceso puede tardar algunos minutos...</p>
                </div>
              </div>}
              {!cargando && (
                <UsuariosABM
                  data={usuariosABM}
                  cargandoset={setCargando}
                  funcionCargarABM={cargarUsuariosABM}
                  EMPRESASDISPONIBLES={empresasDisponibles}
                  enSesion={usuarioEnSesion} 
                  funcionUSUARIO_CONEXIONES={cargarUSUARIO_CONEXIONABM}
                  dataUsuario_Conexiones={USUARIO_CONEXIOBESABM}
                  dataConexiones={conexionesABM}
                  funcionCONEXIONES={cargarConexionesABM}
                  VolverHome={() => { seteardefault(); cambiarMenu('Home'); cargarUSUARIO_CONEXIONHome(usuarioEnSesion.idUsuario) }}/>)}
            </>)
          default:
            return <div></div>;
        }
      case 'RRHH':
        switch (opcion) {
          case 'Agregar conceptos de liquidacion':
            return (
              <div className='menu2-consulta1'>
                <h3 hidden={mostrarFormulario}><strong>Agregar conceptos de liquidación</strong></h3>
                <form className='formulario-menu2-consulta1' onSubmit={async (e) => { await cargarArchivoExcel(e) }} hidden={mostrarFormulario}>
                  <p className='titulo-menu2'>Ingrese el archivo excel</p>
                  <p>Recuerde que el archivo debe respetar el siguiente formato:</p>
                  <table className='table table-bordered table-sm formatoExcel'>
                    <thead>
                      <tr>
                        <th className='text-center' scope='col'>Legajo</th>
                        <th className='text-center' scope='col'>Apellidos</th>
                        <th className='text-center' scope='col'>Nombre</th>
                        <th className='text-center' scope='col'>Nro. de concepto</th>
                        <th className='text-center' scope='col'>Concepto</th>
                        <th className='text-center' scope='col'>Desde</th>
                        <th className='text-center' scope='col'>Hasta</th>
                      </tr>
                    </thead>
                  </table>
                  <input
                    className="form-control form-control-lg cargaExcel"
                    id="formFileLg"
                    type="file"
                    onChange={handleFileChange}
                    accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    required
                  />
                  <button className='btn btn-primary' type='submit'>Cargar</button>
                </form>
                {cargando && <div className='contenedor-cargando'>
                  <div className="spinner-border text-light" style={{ width: 3 + "rem", height: 3 + "rem" }} role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </div>
                  <div>
                    <p>Este proceso puede tardar algunos minutos...</p>
                  </div>
                </div>}
                <div className='tabla-menu2-consulta1' hidden={mostrarTabla}>
                  <button type="button" className="btn btn-success aplicarCambios" data-bs-toggle="modal" data-bs-target="#precaucionAplicarCambios" onClick={() => setHablitarAplicarCambios(!habilitarAplicarCambios)}>
                    Aplicar Cambios
                  </button>
                  <div class="modal fade" id="precaucionAplicarCambios" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1 class="modal-title fs-5" id="staticBackdropLabel">Precaución!</h1>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          {`Los cambios se harán permanentes.`}<br />
                          {`Se utilizarán los datos provenientes de:`}<br />
                          {`Servidor: ${usuarioEnSesion.Servidor}`},<br />
                          {`Base de datos: ${usuarioEnSesion.Base}`}<br />
                          {'¿Desea continuar?'}
                        </div>
                        <div class="modal-footer">
                          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setHablitarAplicarCambios(!habilitarAplicarCambios)}>Cancelar</button>
                          <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={enviarALaBase}>Aplicar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {rows.length > 0 && (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          {Object.keys(rows[0]).map((key) => (
                            <th className={(key === 'Desde') ? 'text-center tabla-fecha' : (key === 'Hasta') ? 'text-center tabla-fecha' : 'text-center'} scope='col' key={key}>{key}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, i) => (
                          <tr key={i}>
                            {Object.values(row).map((value, j) => (
                              <td className='text-center' key={j}>{value}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
                <div className='resultado-menu2-consulta1' hidden={mostrarResultado}>
                  <p className='titulo-resultado-menu2'><strong>Resultado de la operación</strong></p>
                  <table className='table table-bordered tablaResultado'>
                    <thead>
                      <tr>
                        <th scope='col'>Tipo</th>
                        <th scope='col'>Cantidad de datos</th>
                        <th scope='col'>Link de descarga</th>
                      </tr>
                    </thead>
                    <tbody>
                      <FilaResultado
                        Nombreclase='table-success'
                        Titulo='Ingresados Correctamente'
                        Variable={resultadosExitosos}
                        Funcion={generarExcel}
                        MensajeExcel="Resultados Exitosos"
                      />
                      <FilaResultado
                        Nombreclase='table-danger'
                        Titulo='Datos Erróneos'
                        Variable={resultadosErroneos}
                        Funcion={generarExcel}
                        MensajeExcel="Resultados Erroneos"
                      />
                      <FilaResultado
                        Nombreclase='table-warning'
                        Titulo='No procesados'
                        Variable={resultadosNoProcesados}
                        Funcion={generarExcel}
                        MensajeExcel="Resultados No Procesados"
                      />
                    </tbody>
                  </table>
                  <button className='btn btn-primary volverHome' type='button' onClick={() => { seteardefault(); cambiarMenu('Home'); }}>Volver al menú</button>
                </div>
                <button className='btn btn-secondary irArriba' onClick={IrArriba} id="IrArriba" title="Ir Arriba" hidden={mostrarTabla}>Ir Arriba</button>
              </div>);
          case 'Eliminar conceptos de liquidacion': {
            return (
              <div className='menu2-consulta1'>
                <h3 hidden={mostrarFormulario}><strong>Eliminar conceptos de liquidación</strong></h3>
                <form className='formulario-menu2-consulta1' onSubmit={async (e) => { await cargarArchivoExcel(e) }} hidden={mostrarFormulario}>
                  <p className='titulo-menu2'>Ingrese el archivo excel</p>
                  <p>Recuerde que el archivo debe respetar el siguiente formato:</p>
                  <table className='table table-bordered table-sm formatoExcel'>
                    <thead>
                      <tr>
                        <th className='text-center' scope='col'>Legajo</th>
                        <th className='text-center' scope='col'>Apellidos</th>
                        <th className='text-center' scope='col'>Nombre</th>
                        <th className='text-center' scope='col'>Nro. de concepto</th>
                        <th className='text-center' scope='col'>Concepto</th>
                        <th className='text-center' scope='col'>Desde</th>
                        <th className='text-center' scope='col'>Hasta</th>
                      </tr>
                    </thead>
                  </table>
                  <input
                    className="form-control form-control-lg cargaExcel"
                    id="formFileLg"
                    type="file"
                    onChange={handleFileChange}
                    accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    required
                  />
                  <button className='btn btn-primary' type='submit'>Cargar</button>
                </form>
                {cargando && <div className='contenedor-cargando'>
                  <div className="spinner-border text-light" style={{ width: 3 + "rem", height: 3 + "rem" }} role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </div>
                  <div>
                    <p>Este proceso puede tardar algunos minutos...</p>
                  </div>
                </div>}
                <div className='tabla-menu2-consulta1' hidden={mostrarTabla}>
                  <button type="button" className="btn btn-success aplicarCambios" data-bs-toggle="modal" data-bs-target="#precaucionAplicarCambios" onClick={() => setHablitarAplicarCambios(!habilitarAplicarCambios)}>
                    Aplicar Cambios
                  </button>
                  <div class="modal fade" id="precaucionAplicarCambios" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1 class="modal-title fs-5" id="staticBackdropLabel">Precaución!</h1>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          {`Los cambios se harán permanentes.`}<br />
                          {`Se utilizarán los datos provenientes de:`}<br />
                          {`Servidor: ${usuarioEnSesion.Servidor}`},<br />
                          {`Base de datos: ${usuarioEnSesion.Base}`}<br />
                          {'¿Desea continuar?'}
                        </div>
                        <div class="modal-footer">
                          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setHablitarAplicarCambios(!habilitarAplicarCambios)}>Cancelar</button>
                          <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={enviarALaBaseBORRAR}>Aplicar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {rows.length > 0 && (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          {Object.keys(rows[0]).map((key) => (
                            <th className={(key === 'Desde') ? 'text-center tabla-fecha' : (key === 'Hasta') ? 'text-center tabla-fecha' : 'text-center'} scope='col' key={key}>{key}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, i) => (
                          <tr key={i}>
                            {Object.values(row).map((value, j) => (
                              <td className='text-center' key={j}>{value}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
                <div className='resultado-menu2-consulta1' hidden={mostrarResultado}>
                  <p className='titulo-resultado-menu2'><strong>Resultado de la operación</strong></p>
                  <table className='table table-bordered tablaResultado'>
                    <thead>
                      <tr>
                        <th scope='col'>Tipo</th>
                        <th scope='col'>Cantidad de datos</th>
                        <th scope='col'>Link de descarga</th>
                      </tr>
                    </thead>
                    <tbody>
                      <FilaResultado
                        Nombreclase='table-success'
                        Titulo='Eliminados Correctamente'
                        Variable={resultadosEliminacionExitosos}
                        Funcion={generarExcel}
                        MensajeExcel="Resultados de eliminacion Exitosos"
                      />
                      <FilaResultado
                        Nombreclase='table-danger'
                        Titulo='Datos Erróneos'
                        Variable={resultadosEliminacionErroneos}
                        Funcion={generarExcel}
                        MensajeExcel="Resultados de eliminacion Erroneos"
                      />
                      <FilaResultado
                        Nombreclase='table-warning'
                        Titulo='No procesados'
                        Variable={resultadosEliminacionNoProcesados}
                        Funcion={generarExcel}
                        MensajeExcel="Resultados de eliminacion No Procesados"
                      />
                    </tbody>
                  </table>
                  <button className='btn btn-primary volverHome' type='button' onClick={() => { seteardefault(); cambiarMenu('Home'); }}>Volver al menú</button>
                </div>
                <button className='btn btn-secondary irArriba' onClick={IrArriba} id="IrArriba" title="Ir Arriba" hidden={mostrarTabla}>Ir Arriba</button>
              </div>
            )
          }
          case 'actualizacionSueldoBasico': {
            return (
              <div className='menu2-consulta1'>
                <h3 hidden={mostrarSeleccionConfig}>Seleccione una de las configuraciones disponibles</h3>
                <form className='formulario-menu2-consulta1' hidden={mostrarSeleccionConfig}>
                  <select className='form-select form-select-sm' id='select-configuracionSB-ActualizacionSueldo' value={configuracionSBSeleccionada.idConfig} onChange={async (e) => { e.preventDefault(); manejarSeleccionCSB(e.target.value).then((result)=>console.log(configuracionSBSeleccionada))}}>
                    <option value='default'>Seleccione la configuración para el proceso</option>
                    {configuracionesSBDisponibles.map((config) => (
                      <option key={config.idConfig} value={config.idConfig}>
                        {config.Nombre}{' - '}Codigos: {config.Codigos}
                      </option>
                    ))}
                  </select>
                  <button className='btn btn-primary' onClick={manejarSeleccionActualizacionSueldo}>Seleccionar</button>
                </form>

                <h3 hidden={mostrarFormulario}><strong>Actualización de sueldo básico</strong></h3>
                <form className='formulario-menu2-consulta1' onSubmit={async (e) => { await cargarExcelSueldos(e) }} hidden={mostrarFormulario}>
                  <p className='titulo-menu2'>Ingrese el archivo excel</p>
                  <p>Recuerde que el archivo debe respetar el siguiente formato:</p>
                  <table className='table table-bordered table-sm formatoExcel'>
                    <thead>
                      <tr>
                        <th className='text-center' scope='col'>Legajo</th>
                        <th className='text-center' scope='col'>Apellidos</th>
                        <th className='text-center' scope='col'>Nombre</th>
                        <th className='text-center' scope='col'>Sueldo Básico</th>
                      </tr>
                    </thead>
                  </table>
                  <input
                    className="form-control form-control-lg cargaExcel"
                    id="formFileLg"
                    type="file"
                    onChange={handleFileChange}
                    accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    required
                  />
                  <button className='btn btn-primary' type='submit'>Cargar</button>
                </form>
                {cargando && <div className='contenedor-cargando'>
                  <div className="spinner-border text-light" style={{ width: 3 + "rem", height: 3 + "rem" }} role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </div>
                  <div>
                    <p>Este proceso puede tardar algunos minutos...</p>
                  </div>
                </div>}
                <div className='tabla-menu2-consulta1' hidden={mostrarTabla}>
                  <button type="button" className="btn btn-success aplicarCambios" data-bs-toggle="modal" data-bs-target="#precaucionAplicarCambios" onClick={() => setHablitarAplicarCambios(!habilitarAplicarCambios)}>
                    Aplicar Cambios
                  </button>
                  <div class="modal fade" id="precaucionAplicarCambios" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1 class="modal-title fs-5" id="staticBackdropLabel">Precaución!</h1>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-wrap">
                          {`Los cambios se harán permanentes.`}<br /><br />
                          {`Se utilizarán los datos provenientes de:`}<br />
                          <strong>{`Servidor: `}</strong>
                          {usuarioEnSesion.Servidor} <br />
                          <strong>{`Base de datos: `}</strong>
                          {usuarioEnSesion.Base}<br />
                          <strong>{`Categorias: `}<br /></strong>
                          {(configuracionSBSeleccionada.idConfig !== 'default') && (
                            configuracionSBSeleccionada.Codigos.split(',').map(codigo => (
                              <span key={codigo}>{codigo + '; '}</span>
                            ))
                          )}
                          <br /> <br />
                          {'¿Desea continuar?'}
                        </div>
                        <div class="modal-footer">
                          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setHablitarAplicarCambios(!habilitarAplicarCambios)}>Cancelar</button>
                          <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={enviarActualizacionSueldos}>Aplicar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {rows.length > 0 && (
                    <table className="table table-bordered tabla-sueldos">
                      <thead>
                        <tr>
                          {Object.keys(rows[0]).map((key) => (
                            <th className='text-center' scope='col' key={key}>{key}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, i) => (
                          <tr key={i}>
                            {Object.values(row).map((value, j) => (
                              <td className='text-center' key={j}>{value}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>

                <div className='resultado-menu2-consulta1' hidden={mostrarResultado}>
                  <p className='titulo-resultado-menu2'><strong>Resultado de la operación</strong></p>
                  <table className='table table-bordered tablaResultado'>
                    <thead>
                      <tr>
                        <th scope='col'>Tipo</th>
                        <th scope='col'>Cantidad de datos</th>
                        <th scope='col'>Link de descarga</th>
                      </tr>
                    </thead>
                    <tbody>
                      <FilaResultado
                        Nombreclase='table-success'
                        Titulo='Actualizados Correctamente'
                        Variable={resultadosExitosos}
                        Funcion={generarExcelSueldos}
                        MensajeExcel="Resultados de actualización Exitosos"
                      />
                      <FilaResultado
                        Nombreclase='table-danger'
                        Titulo='Datos Erróneos'
                        Variable={resultadosErroneos}
                        Funcion={generarExcelSueldos}
                        MensajeExcel="Resultados de actualización Erroneos"
                      />
                      <FilaResultado
                        Nombreclase='table-warning'
                        Titulo='No procesados'
                        Variable={resultadosNoProcesados}
                        Funcion={generarExcelSueldos}
                        MensajeExcel="Resultados de actualización No Procesados"
                      />
                    </tbody>
                  </table>
                  <button className='btn btn-primary volverHome' type='button' onClick={() => { seteardefault(); cambiarMenu('Home'); }}>Volver al menú</button>
                </div>
                <button className='btn btn-secondary irArriba' onClick={IrArriba} id="IrArriba" title="Ir Arriba" hidden={mostrarTabla}>Ir Arriba</button>
              </div>
            )
          }
          default:
            return <div></div>;
        }
      case 'Home':
        return (
          <>
            <div className='Home' hidden={mostrarHome}>
              <div className='contenedor-Home'>
                <h1 className="title-secondary mt-2 text-uppercase font-weight-extra-bold">Bienvenido!</h1>
                <form className="form formulario-Home" onSubmit={manejarLogin}>
                  <h3>Login</h3>
                  <div className='form-floating'>
                    <input className='form-control mb-3' type='email' id='usuariologin' placeholder='Usuario@Ejemplo.com' onChange={(e) => setUserLogin(e.target.value)} required />
                    <label htmlFor='usuariologin'>E-Mail</label>
                  </div>
                  <div className='form-floating'>
                    <input className='form-control' type='password' id='clavelogin' placeholder='Contraseña' onChange={(e) => setPasswLogin(e.target.value)} required />
                    <label htmlFor='clavelogin'>Contraseña</label>
                  </div>
                  <br></br>
                  <button type='submit' className='btn btn-primary'>Iniciar sesión</button>
                </form>
              </div>
              <footer className='footerLogin'>Powered By <a href='https://sinergy-apps.com/'>Sinergy OGS</a></footer>
            </div>
            {usuarioEnSesion.idUsuario && (
              <div className='Home'>
                <div className='contenedor-Home'>
                  <PantallaHome path={usuarioEnSesion.LogoPrincipal} />
                </div>
                <div className='tabla-conexiones-home'>
                  <table className="table align-middle table-bordered tabla-abm-RolesYPermisos">
                    <thead>
                      <tr >
                        <th className='text-center' scope='col'>Nombre conexión</th>
                        <th className='text-center' scope='col'>Empresa</th>
                        <th className='text-center' scope='col'>Servidor</th>
                        <th className='text-center' scope='col'>Base</th>
                        <th className='text-center' scope='col'>Por defecto</th>
                        <th className='text-center' scope='col'>En sesión</th>
                        <th className='text-center' scope='col'>Seleccione la conexión que quiere usar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {conexionesActivasHome.map((row, index) => (
                        <tr key={index}>
                          <td className='text-center text-truncate'>{row.nombre}</td>
                          <td className='text-center'>{row.empresa}</td>
                          <td className='text-center'>{row.servidor}</td>
                          <td className='text-center'>{row.base}</td>
                          <td className='text-center'>{(row.XDef) ? 'Si' : 'No'}</td>
                          <td className='text-center'>{(row.idConexion === usuarioEnSesion.idConexiones) ? 'En uso' : 'No'}</td>
                          <td className="text-center">
                            <button className="btn btn-outline-success" onClick={async () => { await cambiarConexion(row); mostrarCambioConexion(row.nombre); }} disabled={(row.idConexion === usuarioEnSesion.idConexiones) ? true : false}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        );
      default:{
        break;
      }
    }
  };

  function seteardefault() {
    setMostrarFormulario(false);
    setMostrarTabla(true);
    setCargando(false);
    setMostrarResultado(true);
    setResultadosExitosos([]);
    setResultadosErroneos([]);
    setResultadosNoProcesados([]);
    setResultadosEliminacionExitosos([]);
    setResultadosEliminacionErroneos([]);
    setResultadosEliminacionNoProcesados([]);
  };

  return (
    <div className="Aplicacion">
      <div className="barra-lateral">
        <div className="contenedor-titulo-barra-lateral">
          <LogoBarra path={usuarioEnSesion.LogoMiniatura} />
          <button className='titulo-barra-lateral btn-toggle-nav' onClick={() => { seteardefault(); cambiarMenu('Home'); cargarUSUARIO_CONEXIONHome(usuarioEnSesion.idUsuario) }} disabled={usarBarraLateral}>Home</button>
        </div>
        <hr></hr>
        <button className="btn btn-toggle collapsed menu"
          data-bs-toggle="collapse" data-bs-target="#menu-collapse" aria-expanded="false" disabled={usarBarraLateral}
          hidden={(usuarioEnSesion.TabEmp || usuarioEnSesion.TabConex || usuarioEnSesion.TabConfig || usuarioEnSesion.TabRoles || usuarioEnSesion.TabUsuarios)?false:true}>
          Tablas
        </button>
        <div className='collapse' id='menu-collapse'>
          <ul className="btn-toggle-nav lista-barra-lateral">
            <li><a href="#" className="opcion-barra-lateral" onClick={usuarioEnSesion.TabEmp ? () => { seteardefault(); cambiarMenu('Tablas'); cambiarOpcion('Empresas'); cargarTodasEmpresasConDatos() } : () => Toast.fire({ icon: "error", title: 'Acceso denegado' })} disabled={usarBarraLateral}
            hidden={(usuarioEnSesion.TabEmp)?false:true}>Empresas</a></li>

            <li><a href="#" className="opcion-barra-lateral" onClick={(usuarioEnSesion.TabConex || usuarioEnSesion.TabConfig)? async () => { seteardefault(); await cargarConexionesABM(usuarioEnSesion); cambiarMenu('Tablas'); cambiarOpcion('Conexiones'); } : () => Toast.fire({ icon: "error", title: 'Acceso denegado' })} disabled={usarBarraLateral}
            hidden={(usuarioEnSesion.TabConex || usuarioEnSesion.TabConfig)?false:true}>Conexiones</a></li>

            {/* <li><a href="#" className="opcion-barra-lateral" onClick={usuarioEnSesion.TabConfig ? () => { seteardefault(); cambiarMenu('Tablas'); cambiarOpcion('ConfigSueldoBasico'); cargarConfigSBABM(); cargarEmpresas(); cargarConexionesABM(usuarioEnSesion); } : () => Toast.fire({ icon: "error", title: 'Acceso denegado' })} hidden={usarBarraLateral}
            >Configuracion sueldo básico</a></li> */}

            <li><a href="#" className="opcion-barra-lateral" onClick={usuarioEnSesion.TabRoles ? () => { seteardefault(); cambiarMenu('Tablas'); cambiarOpcion('Roles'); cargarRolesABM(); } : () => Toast.fire({ icon: "error", title: 'Acceso denegado' })} disabled={usarBarraLateral}
            hidden={(usuarioEnSesion.TabRoles)?false:true}>Roles</a></li>

            <li><a href="#" className="opcion-barra-lateral" onClick={usuarioEnSesion.TabUsuarios ? async () => { seteardefault(); await cargarUsuariosABM(usuarioEnSesion); setCargando(true); await cargarEmpresas().then((result)=>{if(result){setCargando(false)}}); cambiarMenu('Tablas'); cambiarOpcion('Usuarios'); } : () => Toast.fire({ icon: "error", title: 'Acceso denegado' })} disabled={usarBarraLateral}
            hidden={(usuarioEnSesion.TabUsuarios)?false:true}>Usuarios</a></li>

          </ul>
        </div>
        <hr hidden={(usuarioEnSesion.TabEmp || usuarioEnSesion.TabConex || usuarioEnSesion.TabConfig || usuarioEnSesion.TabRoles || usuarioEnSesion.TabUsuarios)?false:true}></hr>
        <button className="btn btn-toggle collapsed menu"
          data-bs-toggle="collapse" data-bs-target="#menu2-collapse" aria-expanded="false" disabled={usarBarraLateral}
          hidden={(usuarioEnSesion.AgrCon || usuarioEnSesion.ElimCon || usuarioEnSesion.ActSueldo)?false:true}>
          RRHH
        </button>
        <div className='collapse' id='menu2-collapse'>
          <ul className="btn-toggle-nav lista-barra-lateral">
            <li><a href="#" className="opcion-barra-lateral" onClick={usuarioEnSesion.AgrCon ? () => { seteardefault(); cambiarMenu('RRHH'); cambiarOpcion('Agregar conceptos de liquidacion'); } : () => Toast.fire({ icon: "error", title: 'Acceso denegado' })} disabled={usarBarraLateral}
            hidden={(usuarioEnSesion.AgrCon)?false:true}>Agregar conceptos de liquidación</a></li>

            <li><a href="#" className="opcion-barra-lateral" onClick={usuarioEnSesion.ElimCon ? () => { seteardefault(); cambiarMenu('RRHH'); cambiarOpcion('Eliminar conceptos de liquidacion'); } : () => Toast.fire({ icon: "error", title: 'Acceso denegado' })} disabled={usarBarraLateral}
            hidden={(usuarioEnSesion.ElimCon)?false:true}>Eliminar conceptos de liquidación</a></li>

            <li><a href="#" className="opcion-barra-lateral"
              hidden={(usuarioEnSesion.ActSueldo)?false:true}
              onClick={
                usuarioEnSesion.ActSueldo ?
                  async () => { 
                    seteardefault(); const configuracion = await buscarConfiguracion(usuarioEnSesion.idConexiones);
                    if (configuracion.length !== 0) {
                      if (configuracion.length === 1) {
                        setConfiguracionSBSeleccionada(configuracion[0]);
                      } else {
                        setConfiguracionesSBDisponibles(configuracion);
                        setMostrarSeleccionConfig(false); //hidden de la seleccion de configuraciones en falso
                        setMostrarFormulario(true); //hidden de la actualizacion de sueldos en true
                      }
                      cambiarMenu('RRHH'); cambiarOpcion('actualizacionSueldoBasico'); 
                    } else {
                      Alerta.fire({
                        icon: 'error',
                        title: 'La conexión no posee configuracion para el proceso "actualización de sueldos básicos. '
                      })
                    }
                  }
                  : () => Toast.fire({ icon: "error", title: 'Acceso denegado' })
              }
              disabled={usarBarraLateral} //
            >Actualización de sueldo básico</a></li>

          </ul>
        </div>
        <div className='conexion-barra-lateral' onClick={() => Swal.fire({
          toast: true,
          position: "bottom-start",
          width: 160 + "px",
          background: "#003087",
          showCancelButton: true,
          confirmButtonColor: '#0057b8',
          cancelButtonColor: '#b1b1b1',
          confirmButtonText: 'Cerrar sesión',
          allowOutsideClick: true
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })}
          hidden={!conexionElegida}>
          <p className='mb-1'>Conectado a:</p>
          <p className='mb-0'>{usuarioEnSesion.NombreConexion}</p>
          <p className='mb-0'>{usuarioEnSesion.Servidor}</p>
          <p>{usuarioEnSesion.Base}</p>
        </div>
      </div>
      <div className='contenido-principal'>
        {contenidoPagina()}
      </div>
    </div>
  );
}

export default App;