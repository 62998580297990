import { useEffect, useState } from "react";

function LogoBarra({path}){

  const [imagen, setImagen] = useState(path);

  useEffect(()=>{
    setImagen(path)
  }, [path])

  return(
    <img className='logo-barra-lateral' alt='logo-barra' src={imagen}/>
  );
}

export default LogoBarra;