function FilaResultado({ Nombreclase, Titulo, Variable, Funcion, MensajeExcel }){
  return(
    <tr className={Nombreclase}>
      <td>{Titulo}</td>
      <td>Cantidad: {(Variable.length!== 0) ? Variable.length : 0}</td>
      <td className={(Variable.length!== 0)? 'link-tabla-resultados' : ''} onClick={() => {Funcion(Variable, MensajeExcel)}}>Link</td>
    </tr>
  );
}

export default FilaResultado;