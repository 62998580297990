// Plantilla ABM
import { useState } from 'react';
import miScript from '../conexion.js';
import Swal from 'sweetalert2';


function EmpresasABM(
  { data, 
    FuncionCargarEmpresasConDatos, 
    EMPRESASELECCIONADA, 
    cargandoset, 
    VolverHome
  }){

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  const Alerta = Swal.mixin({
    toast: false,
    position: "center",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  const RecargaAlerta = Swal.mixin({
    toast: false,
    position: "center",
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  
  const [itemSeleccionado, setItemSeleccionado] = useState(null);
  const [mostrarPantallaABM, setMostrarPantallaABM] = useState(true);
  const [mostrarMensajeAlta, setMostrarMensajeAlta] = useState(false);
  const [mostrarMensajeMod, setMostrarMensajeMod] = useState(false);

  //Alta
  const [inputNombreEmpresaABMAlta, setInputNombreEmpresaABMAlta] = useState('');
  const [inputLogoPrincipalEmpresaABMAlta, setInputLogoPrincipalEmpresaABMAlta] = useState(null);
  const [inputLogoMiniaturaEmpresaABMAlta, setInputLogoMiniaturaEmpresaABMAlta] = useState(null);

  
  function NombreEmpresaABMAlta(e){
    e.preventDefault();
    setInputNombreEmpresaABMAlta(e.target.value);
  }

  function LogoPrincipalEmpresaABMAlta(e){
    const file = e.target.files[0];
    setInputLogoPrincipalEmpresaABMAlta(file);
  }

  function LogoMiniaturaEmpresaABMAlta(e){
    const file = e.target.files[0];
    setInputLogoMiniaturaEmpresaABMAlta(file);
  }

  function manejarEnvioAlta(){
    if(inputNombreEmpresaABMAlta==='' || inputLogoPrincipalEmpresaABMAlta===null){
      Alerta.fire({
        icon:"warning",
        title:'Complete el campo "Nombre de la empresa"\ny cargue la imagen en \n"Logo Principal".'
      })
    }else{
      let NombresEmpresas = data.map((obj)=> obj.NOMBRE_EMP)
      if(NombresEmpresas.includes(inputNombreEmpresaABMAlta.toUpperCase())){
        Alerta.fire({
          icon: "error",
          title: "Ya existe una empresa con ese nombre."})
      }else{
        setMostrarMensajeAlta(false); 
        setMostrarPantallaABM(true); 
        EmpresaABMAlta()
      }
    }
  }

  async function EmpresaABMAlta(){
    cargandoset(true)
    let resultadoInsertar = await miScript.EmpresaABMAlta(inputNombreEmpresaABMAlta, inputLogoPrincipalEmpresaABMAlta, inputLogoMiniaturaEmpresaABMAlta);
    if(resultadoInsertar.ERROR){
      Alerta.fire({
          icon: "error",
          title: resultadoInsertar.MENSAJE
        });
    }else{
      FuncionCargarEmpresasConDatos();
      Toast.fire({
        icon: "success",
        title: "Empresa creada correctamente."
      });
    }
    cargandoset(false);
  }


  //Modificación

  const [nombreEmpresaABMMod, setNombreEmpresaABMMod] = useState('');
  const [switchEmpresaABMMod, setSwitchEmpresaABMMod] = useState(false);

  function manejarNombreABMMod(e){
    e.preventDefault();
    setNombreEmpresaABMMod(e.target.value);
  }

  function manejarOpcionModificacion(item){
    if(item.NOMBRE_EMP === EMPRESASELECCIONADA){
      Alerta.fire({
        icon: "error",
        title: "No se puede modificar la empresa actualmente \nen sesión."
      });
    }
    else{
      setMostrarPantallaABM(false);
      setMostrarMensajeMod(true);
    }
  }

  async function EmpresaABMMod(item){
    setMostrarMensajeMod(false);
    cargandoset(true);
    try{
      let resultado = await miScript.EmpresaABMMod(item.NOMBRE_EMP, nombreEmpresaABMMod, switchEmpresaABMMod);
      if(resultado.ERROR){
        Alerta.fire({
          icon:'error',
          title: resultado.MENSAJE
        })
      }else{
        FuncionCargarEmpresasConDatos();
          Alerta.fire({
            icon: "success",
            title: resultado.MENSAJE
          });
      }
    }catch(err){
      Toast.fire({
        icon: "error",
        title: "ERROR: "+err
      });
    }
    cargandoset(false);
  }

  //Baja
  async function baja(item){
    if(item.NOMBRE_EMP === EMPRESASELECCIONADA){
      Alerta.fire({
        icon: "error",
        title: "No puede borrar la empresa actualmente en sesión."
      });
    }
    else{
      cargandoset(true)
      try{
        let resultado = await miScript.EmpresaABMBaja(item.NOMBRE_EMP);
        if(resultado.ERROR){
          Alerta.fire({
            icon: "error",
            title: resultado.MENSAJE
          });
        }else{
          FuncionCargarEmpresasConDatos();
          Alerta.fire({
            icon: "success",
            title: resultado.MENSAJE
          });
        }
      }catch(err){
        Alerta.fire({
          icon: "error",
          title: "Error en el proceso, intente de nuevo."
        });
      }
      cargandoset(false);
    }
  } 
  
  async function manejarBaja(row){
    RecargaAlerta.fire(
      {
        icon:'warning', 
        title:`¿Borrar la Empresa ${row.NOMBRE_EMP}?`
      }).then((result)=>{
        if(result.isConfirmed){
          baja(row)
        }
      }
    )
  }

  return(
    <div className='contenedor-abm'>
      {mostrarMensajeAlta && (
        <div className="MensajeAlta">
          <h3>Crear empresa</h3>
          <hr></hr>
          <form className='form-group formulario-abm'>
            <div className='input-group mb-3'>
              <input type='text' placeholder='Nombre de la empresa' className='form-control' aria-label='Nombre' aria-describedby='Nombre' onChange={NombreEmpresaABMAlta} required/>
            </div>
            <p className='recomendacion'>Relación recomendada para el Logo Principal: 16:9</p>
            <div className='input-group mb-3'>
              <input type='file' accept=".jpg, .jpeg, .png, .svg" className='form-control' aria-label='LogoPrincipal' aria-describedby='LogoPrincipal' onChange={LogoPrincipalEmpresaABMAlta} required/>
            </div>
            <p className='recomendacion'>Relación recomendada para el Logo Miniatura: 1:1</p>
            <div className='input-group mb-3'>
              <input type='file' accept=".jpg, .jpeg, .png, .svg" className='form-control' aria-label='LogoMiniatura' aria-describedby='LogoMiniatura' onChange={LogoMiniaturaEmpresaABMAlta}/>
            </div>
          </form>
          <div className="Botones-Mensaje">
            <button type="button" class="btn btn-secondary" onClick={()=>{setMostrarMensajeAlta(false); setMostrarPantallaABM(true)}}>Cancelar</button>
            <button type="button" class="btn btn-success" onClick={manejarEnvioAlta}>Confirmar</button>
          </div>
      </div>
      )}
      {mostrarMensajeMod && (
        <div className="MensajeMod">
          <h3>Modificar Empresa</h3>
          <hr></hr>
          <form className='form-group formulario-abm'>
            <div className='input-group mb-3'>
              <input type='text' placeholder={itemSeleccionado.NOMBRE_EMP} className='form-control' aria-label='Nombre-ABMMod' aria-describedby='Nombre-ABMMod' onChange={manejarNombreABMMod} required/>
            </div>
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" role="switch" id="SwitchEstadoEmpresaABMMod" value={switchEmpresaABMMod} onChange={(e)=>setSwitchEmpresaABMMod(e.target.checked)} checked={switchEmpresaABMMod}/>
              <label className="form-check-label" htmlFor="SwitchEstadoEmpresaABMMod">Estado de la empresa: {(switchEmpresaABMMod) ? ' Activada' : ' Desactivada'}</label>
            </div>
          </form>
          <div className="Botones-Mensaje">
            <button type="button" class="btn btn-secondary" onClick={()=>{setItemSeleccionado(null); setMostrarMensajeMod(false); setMostrarPantallaABM(true)}}>Cancelar</button>
            <button type="button" class="btn btn-primary" onClick={()=>{EmpresaABMMod(itemSeleccionado); setMostrarMensajeMod(false); setMostrarPantallaABM(true)}}>Confirmar</button>
          </div>
      </div>
      )}
      {mostrarPantallaABM && (
        <>
        <div className='contenido-contenedor-abm'>
            <div className="Boton-VolverAUsuarios">
              <button className="btn btn-secondary" onClick={VolverHome}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"/>
                  <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"/>
                </svg>
                {' '}Ir a Pantalla Principal</button>
              <button className="btn btn-success"
                onClick={() => {setMostrarMensajeAlta(true); setMostrarPantallaABM(false);}}> Añadir Empresa{' '}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                </svg>
              </button>
            </div>
            <br/>
          <div className='titulo-contenido-contenedor-abm'>
            <h3><strong>Empresas</strong></h3>
            
          </div>
          <table className="table align-middle table-bordered table-hover tabla-abm">
            <thead>
              <tr >
                <th className='text-center' scope='col'>Nombre</th>
                <th className='text-center' scope='col'>Fecha de modificación</th>
                <th className='text-center' scope='col'>Activa</th>
                <th className='text-center' scope='col' style={{width:150+'px'}}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td className='text-center'>{row.NOMBRE_EMP}</td>
                  <td className='text-center'>{row.FECHA_MOD}</td>
                  <td className='text-center'>{row.ACTIVO?'Si':"No"}</td>
                  <td className='text-center acciones-tabla-abm'>
                    <button className="btn btn-outline-warning" style={{width:45+'%', marginRight: 2+'%'}} onClick={() => {setItemSeleccionado(row); setSwitchEmpresaABMMod(row.ACTIVO); manejarOpcionModificacion(row)}} >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                      </svg>
                    </button>
                    <button className="btn btn-outline-danger" style={{width:45+'%', marginLeft: 2+'%'}} onClick={()=> {manejarBaja(row)}} >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </>
      )}
    </div>
  );
}


export default EmpresasABM;

/*
                    <div className='contenedor-abm'>
                      <div className="grupo-botones-abm">
                        <button className={(mostrarABMEmpresas === 'Alta')? 'btn btn-outline-success active' : 'btn btn-outline-success'} type="button" 
                        onClick={() =>{ eleccionABMEmpresas('Alta'); cargarConexionesActivas(); cargarTodasConexiones();cargarEmpresas(); cargarTodasEmpresas()}}>Alta</button>
                        <button className={(mostrarABMEmpresas === 'Modificacion')? 'btn btn-outline-warning active' : 'btn btn-outline-warning'} type="button" 
                          onClick={() =>{ eleccionABMEmpresas('Modificacion'); cargarConexionesActivas(); cargarTodasConexiones();cargarEmpresas(); cargarTodasEmpresas()}}>Modificación</button>
                        <button className={(mostrarABMEmpresas === 'Baja')? 'btn btn-outline-danger active' : 'btn btn-outline-danger'} type="button" 
                        onClick={() => {eleccionABMEmpresas('Baja'); cargarConexionesActivas(); cargarTodasConexiones();cargarEmpresas(); cargarTodasEmpresas()}}
                        >Baja</button>
                      </div>

                      {(mostrarABMEmpresas === 'Alta') && <>
                      <div className='contenedor-formulario-abm'> 
                        <h2 className='subtitulo-formulario-abm'>Añadir empresa</h2>
                        <form className='form-group formulario-abm' onSubmit={EmpresaABMAlta} ref={refEmpresasABMAlta}>
                          <div className='input-group mb-3'>
                            <input type='text' placeholder='Nombre de la empresa' className='form-control' aria-label='Nombre' aria-describedby='Nombre' onChange={NombreEmpresaABMAlta} required/>
                          </div>
                          <p className='recomendacion'>Relación recomendada para el Logo Principal: 16:9</p>
                          <div className='input-group mb-3'>
                            <input type='file' accept=".jpg, .jpeg, .png, .svg" className='form-control' aria-label='LogoPrincipal' aria-describedby='LogoPrincipal' onChange={LogoPrincipalEmpresaABMAlta} required/>
                          </div>
                          <p className='recomendacion'>Relación recomendada para el Logo Miniatura: 1:1</p>
                          <div className='input-group mb-3'>
                            <input type='file' accept=".jpg, .jpeg, .png, .svg" className='form-control' aria-label='LogoMiniatura' aria-describedby='LogoMiniatura' onChange={LogoMiniaturaEmpresaABMAlta}/>
                          </div>
                          <button type="submit" className="btn btn-primary boton-formulario-Home mb-3">
                            Grabar
                          </button>
                        </form>
                      </div></>}

                      {(mostrarABMEmpresas === 'Modificacion') && <>
                      <div className='contenedor-formulario-abm'>
                        <h2 className='subtitulo-formulario-abm'>Modificar empresa</h2>
                        <form className='form-group formulario-abm' onSubmit={EmpresaABMMod} ref={refEmpresasABMMod}>
                          <select className='form-select form-select-sm' id='select-empresas-ABMMod' value={seleccionEmpresaABMMod} onChange={manejarOpcionEmpresasABMMod}>
                            <option value='default'>Seleccione la empresa a modificar</option>
                            {todasEmpresas.map((empresa) => (
                              <option key={empresa} value={empresa}>
                                {empresa}
                              </option>
                            ))}
                          </select>
                          <div className='input-group mb-3'>
                            <input type='text' placeholder='Nuevo nombre' className='form-control' aria-label='Nombre-ABMMod' aria-describedby='Nombre-ABMMod' onChange={manejarNombreABMMod} required/>
                          </div>
                          <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" id="SwitchEstadoEmpresaABMMod" value={switchEmpresaABMMod} onChange={(e)=>setSwitchEmpresaABMMod(e.target.checked)} />
                            <label className="form-check-label" htmlFor="SwitchEstadoEmpresaABMMod">{(switchEmpresaABMMod) ? 'On: activar empresa' : 'Off: desactivar empresa'}</label>
                          </div>
                          <button type="submit" className="btn btn-primary boton-formulario-Home mb-3">
                            Grabar
                          </button>
                        </form>
                      </div></>}

                      {(mostrarABMEmpresas === 'Baja') && <>
                      <div className='contenedor-formulario-abm'>
                        <h2 className='subtitulo-formulario-abm'>Baja empresa</h2>
                        <form className='form-group formulario-abm' onSubmit={EmpresaABMBaja} ref={refEmpresasABMBaja}>
                        <select className='form-select form-select-sm' id='select-empresas-ABMBaja' value={seleccionEmpresaABMBaja}  onChange={manejarOpcionEmpresasABMBaja} >
                          <option value='default'>Seleccione la empresa a dar de baja</option>
                          {empresasDisponibles.map((empresa) => (
                            <option key={empresa} value={empresa}>
                              {empresa}
                            </option>
                          ))}
                        </select>
                          <button type="submit" className="btn btn-primary boton-formulario-Home mb-3">
                            Dar baja
                          </button>
                        </form>
                      </div></>}
                    </div>

*/