import { useState, useRef } from "react";
import miScript from '../conexion.js';
import Swal from 'sweetalert2';

function ConexionesABM(
  { data, 
    conexSelec, 
    cargandoset, 
    conexionesABMset, 
    EMPRESASDISPONIBLES, 
    FuncionCargarEmpresas, 
    FuncionCargarABM, 
    funcionCargarConfiguraciones, 
    dataConfiguraciones,
    enSesion, 
    VolverHome }){

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  const Alerta = Swal.mixin({
    toast: false,
    position: "center",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  const RecargaAlerta = Swal.mixin({
    toast: false,
    position: "center",
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  const [itemSeleccionado, setItemSeleccionado] = useState(null);
  const [mostrarPantallaABM, setMostrarPantallaABM] = useState(true);
  const [mostrarMensajeAlta, setMostrarMensajeAlta] = useState(false);
  const [mostrarMensajeMod, setMostrarMensajeMod] = useState(false);


  //baja
  const baja = async (elem) => {
    if(conexSelec!==elem.idConexion){
      let resultado = await miScript.ConexionABMBaja(elem.Nombre, elem.idEmpresa);
      if(resultado.ERROR){
        Alerta.fire({
          icon: "error",
          title: resultado.MENSAJE
        });
      }else{
        await FuncionCargarABM(enSesion)
        Alerta.fire({
          icon: "success",
          title: resultado.MENSAJE
        });
      }
    }else{
      Alerta.fire({
        icon: "error",
        title: "No se puede dar de baja la conexión en sesión."
      });
    }
  }

  //mod
  const [nombreConexABMMod, setNombreConexABMMod] = useState('');
  const [baseConexABMMod, setBaseConexABMMod] = useState('');
  const [servidorConexABMMod, setServidorConexABMMod] = useState('');
  const [conexionActivaABMMod, setConexionActivaABMMod] = useState(false);

  const refConexionesABMMod = useRef();


  function manejarNombreConexionABMMod(e){
    e.preventDefault();
    setNombreConexABMMod(e.target.value);
  }

  function manejarBaseConexionABMMod(e){
    e.preventDefault();
    setBaseConexABMMod(e.target.value);
  }

  function manejarServidorConexionABMMod(e){
    e.preventDefault();
    setServidorConexABMMod(e.target.value);
  }

  async function ConexionesABMMod(){
    try{
      let resultado = await miScript.ConexionABMMod(itemSeleccionado.Nombre, nombreConexABMMod, baseConexABMMod, servidorConexABMMod, conexionActivaABMMod, itemSeleccionado.idEmpresa);
      setNombreConexABMMod('');setServidorConexABMMod('');setBaseConexABMMod('');setConexionActivaABMMod(false);
      if(resultado.ERROR){
        Alerta.fire({
          icon: "error",
          title: resultado.MENSAJE
        });
      }else{
        Alerta.fire({
          icon: "success",
          title: resultado.MENSAJE
        });
        FuncionCargarABM(enSesion);
      }
    }catch(err){
      Alerta.fire({
        icon: "error",
        title: "Error, intente nuevamente."
      });
    }
  }

  function manejarOpcionModificacion(item){
    if(conexSelec===item.Nombre){
      Toast.fire({
        icon: "warning",
        title: "No puedes modificar la conexión actual."
      });
    }else{
      setMostrarMensajeMod(true); 
      setMostrarPantallaABM(false)}
  }

  //Alta
  const [nombreConexABMAlta, setNombreConexABMAlta] = useState('');
  const [selecionConexionABMAlta, setSeleccionConexionABMAlta] = useState();
  const [servidorConexABMAlta, setServidorConexABMAlta] = useState('');
  const [baseConexABMAlta, setBaseConexABMAlta] = useState('');

  function manejarNombreConexABMAlta(e){
    e.preventDefault();
    setNombreConexABMAlta(e.target.value);
  }

  function manejarOpcionConexionABMAlta(e){
    e.preventDefault();
    setSeleccionConexionABMAlta(e.target.value);
  }

  function manejarServidorConexABMAlta(e){
    e.preventDefault();
    setServidorConexABMAlta(e.target.value);
  }

  function manejarBaseConexABMAlta(e){
    e.preventDefault();
    setBaseConexABMAlta(e.target.value);
  }

  function manejarEnvioAlta(){
    if(nombreConexABMAlta==='' || servidorConexABMAlta==='' || baseConexABMAlta===''){
      Alerta.fire({
        icon:"warning",
        title:'Complete los campos "Empresa", \n"Servidor",\n"Base de Datos".'
      })
    }else{
      if(selecionConexionABMAlta === 'default'){
        Toast.fire({
          icon: "warning",
          title: "Por favor, seleccione una de las opciones."
        });
      }else{
        setMostrarMensajeAlta(false); 
        setMostrarPantallaABM(true); 
        ConexionesABMAlta()
      }
    }
  }
  
  async function ConexionesABMAlta(){
    try{
      let resultado = await miScript.ConexionABMAlta(selecionConexionABMAlta, nombreConexABMAlta, servidorConexABMAlta, baseConexABMAlta);
      setSeleccionConexionABMAlta('default');
      if(resultado.ERROR){
        Alerta.fire({
          icon:'error',
          title: resultado.MENSAJE
        })
      }else{
        Toast.fire({
          icon:'success',
          title: resultado.MENSAJE
        })
      }
    }catch(err){
      Alerta.fire({
        icon: "error",
        title: "Error: "+err
      });
    }
    FuncionCargarABM(enSesion);
  }

  const [mostrarConfig, setMostrarConfig] = useState(false);
  const [mostrarConfigAlta, setMostrarConfigAlta] = useState(false);

  const [categoriasActivas, setCategoriasActivas] = useState();
  const [nombreTablaABMAlta, setNombreTablaABMAlta] = useState('');
  const [nombreConfiguracionSB, setNombreConfiguracionSB] = useState('')

  async function manejarEnvioConfigAlta(){
    if(nombreConfiguracionSB === '' || nombreTablaABMAlta===''){
      Alerta.fire({
        icon:"warning",
        title:'Atencion',
        text: 'Complete todos los campos.'
      })
    }else{
      let buscarCategorias = await miScript.BuscarCategoriasABMAlta(itemSeleccionado.idConexion, nombreTablaABMAlta);
      if(buscarCategorias.ERROR){
        Alerta.fire({
          icon: 'error',
          title: buscarCategorias.MENSAJE
        })
      }else{
        if(buscarCategorias.CONTENIDO.length === 0){
          Alerta.fire({
            icon:'warning',
            tittle: 'No se encontraron categorias, asegúrese de que el nombre de la tabla sea correcto.'
          })
        }else{
          setCategoriasActivas(buscarCategorias.CONTENIDO);
        }
      }
    }
  }

  const [categoriasSeleccionadas, setCategoriasSeleccionadas] = useState({});

  const manejarSeleccionCategoria = (option) => {
    setCategoriasSeleccionadas((prevCategorias) => ({
      ...prevCategorias,
      [option.ID_CATEGORIA]: {
        COD_CATEGORIA: option.COD_CATEGORIA,
        seleccionado: !prevCategorias[option.ID_CATEGORIA]?.seleccionado,
      },
    }));
  };

  
  async function ConfigABMAlta(){
    const idsSeleccionadas = [];
    const codigosSeleccionadas = [];
    
    Object.entries(categoriasSeleccionadas)
      .filter(([id, categoria]) => categoria.seleccionado)
      .forEach(([id, categoria]) => {
        idsSeleccionadas.push(id);
        codigosSeleccionadas.push(categoria.COD_CATEGORIA);
    });

    try{
      let insertar = await miScript.ConfigABMAlta(nombreConfiguracionSB, itemSeleccionado.idConexion,nombreTablaABMAlta, idsSeleccionadas, codigosSeleccionadas);
      if(insertar.ERROR){
        Alerta.fire({
          icon:'error',
          title: insertar.MENSAJE
        });
      }else{
        await funcionCargarConfiguraciones(itemSeleccionado.idConexion);
        Toast.fire({
          icon: 'success',
          title: insertar.MENSAJE
        })
      }
    }catch(err){
      Alerta.fire({
        icon:'error',
        title: 'Error: ',
        text: ''+err
      })
    }
  }

  async function bajaConfig(id){
    try{
      let baja = await miScript.bajaConfigABM(id);
      if(baja.ERROR){
        Alerta.fire({
          icon:'error',
          title: 'Error',
          text: baja.MENSAJE
        })
      }else{
        Alerta.fire({
          icon:'success',
          title: 'Baja completada'
        });
        await funcionCargarConfiguraciones(itemSeleccionado.idConexion);
      }
    }catch(err){
      Alerta.fire({
        icon:'error',
        title: 'Error',
        text: 'Error en el proceso, intente nuevamente.'
      })
    }
  }

  async function manejarBajaCon(row){
    RecargaAlerta.fire(
      {
        icon:'warning', 
        title:`¿Borrar la conexión ${row.Nombre}?`
      }).then((result)=>{
        if(result.isConfirmed){
          baja(row)
        }
      }
    )
  }


  return(
    <div className='contenedor-abm'>
      {/* {mostrarMensajeBaja && (
        <div className="MensajeBaja">
          <p>¿Borrar la conexión <strong>{itemSeleccionado.Nombre}</strong>?</p>
          <div className="Botones-Mensaje">
            <button type="button" class="btn btn-secondary" onClick={()=>{setItemSeleccionado(null); setMostrarMensajeBaja(false)}}>Cancelar</button>
            <button type="button" class="btn btn-danger" onClick={}>Confirmar</button>
          </div>
      </div>
      )} */}

      {mostrarMensajeMod && (
        <div className="MensajeMod">
          <h3>Modificar conexión</h3>
          <form className='form-group formulario-abm' ref={refConexionesABMMod}>
            <p>Pertenece a la empresa: <strong>{itemSeleccionado.Empresa}</strong></p>
            <div className='input-group mb-3'>
              <input type='text' placeholder={itemSeleccionado.Nombre} className='form-control' aria-label='Nombre-Conexion-ABMMod' aria-describedby='' onChange={manejarNombreConexionABMMod}/>
            </div>
            <div className='input-group mb-3'>
              <input type='text' placeholder={itemSeleccionado.Servidor} className='form-control' aria-label='Servidor-Conexion-ABMMod' aria-describedby='' onChange={manejarServidorConexionABMMod}/>
            </div>
            <div className='input-group mb-3'>
              <input type='text' placeholder={itemSeleccionado.BaseDatos} className='form-control' aria-label='Nombre-Conexion-ABMMod' aria-describedby='' onChange={manejarBaseConexionABMMod}/>
            </div>
            <div className="form-check">
              <input className="form-check-input" type='checkbox' id="ConexionABMModActiva"checked={conexionActivaABMMod} onChange={(e) => setConexionActivaABMMod(e.target.checked)}/>
              <label className="form-check-label" htmlFor="ConexionABMModActiva"> Activar conexión: {(conexionActivaABMMod)? 'SI' : 'NO'} </label>
            </div>
          </form>
          <div className="Botones-Mensaje">
            <button type="button" class="btn btn-secondary" onClick={()=>{setItemSeleccionado(null); setMostrarMensajeMod(false); setMostrarPantallaABM(true)}}>Cancelar</button>
            <button type="button" class="btn btn-primary" onClick={()=>{ConexionesABMMod(); setMostrarMensajeMod(false); setMostrarPantallaABM(true)}}>Confirmar</button>
          </div>
      </div>
      )}

      {mostrarMensajeAlta && (
        <div className="MensajeAlta">
          <h3>Crear conexión</h3>
          <hr></hr>
          <form className='form-group mb-3 formulario-abm'>
            <select className='form-select form-select-sm' id='select-conexiones-ABMAlta' value={selecionConexionABMAlta}  onChange={manejarOpcionConexionABMAlta} >
              <option value='default'>Seleccione la empresa para la conexión</option>
              {EMPRESASDISPONIBLES.map((empresa) => (
                <option key={empresa} value={empresa}>
                  {empresa}
                </option>
              ))}
            </select>
            <div className='input-group mb-3'>
              <input type='text' placeholder='Nombre de la conexión' className='form-control' aria-label='NombreConex' aria-describedby='NombreConex' id='NombreConexABMAlta' onChange={manejarNombreConexABMAlta}/>
            </div>
            <div className='input-group mb-3'>
              <input type='text' placeholder='Servidor' className='form-control' aria-label='ServidorConex' aria-describedby='ServidorConex' id='ServidorConexABMAlta' onChange={manejarServidorConexABMAlta}/>
            </div>
            <div className='input-group mb-3'>
              <input type='text' placeholder='Base de Datos' className='form-control' aria-label='BaseConex' aria-describedby='BaseConex' id='BaseConexABMAlta' onChange={manejarBaseConexABMAlta}/>
            </div>
          </form>
          <div className="Botones-Mensaje">
            <button type="button" class="btn btn-secondary" onClick={()=>{setMostrarMensajeAlta(false); setMostrarPantallaABM(true); setSeleccionConexionABMAlta('default')}}>Cancelar</button>
            <button type="button" class="btn btn-success" onClick={manejarEnvioAlta}>Confirmar</button>
          </div>
        </div> 
      )}

      {mostrarConfigAlta && (
        <div className="MensajeAlta text-center">
          <h3>Añadir configuración para <br/><strong>{itemSeleccionado.Nombre}</strong></h3>
          {/* Formulario para Alta */}
          <form className='form-group formulario-abm'>
            <div className='input-group mb-3'>
              <input type='text' placeholder='Nombre para la configuración' className='form-control' aria-label='tabla' aria-describedby='tabla' onChange={(e)=>setNombreConfiguracionSB(e.target.value)} />
            </div>
            <div className='input-group mb-3'>
              <input type='text' placeholder='Nombre de la tabla' className='form-control' aria-label='tabla' aria-describedby='tabla' onChange={(e)=>setNombreTablaABMAlta(e.target.value)} disabled={(categoriasActivas)?true:false} />
            </div>
            
            {(categoriasActivas && categoriasActivas.length>0)? (
              <>
              <h3>Seleccione las categorías para esta configuración</h3>
              <form className='form-group formulario-abm formulario-abm-configuracion-categorias'>
                {categoriasActivas.map((option) => (
                  <div className="form-check text-start" key={option.ID_CATEGORIA}>
                    <input
                      className="form-check-input"
                      type='checkbox'
                      id={option.ID_CATEGORIA}
                      name={option.COD_CATEGORIA}
                      checked={categoriasSeleccionadas[option.ID_CATEGORIA]?.seleccionado}
                      onChange={() => manejarSeleccionCategoria(option)}
                    />
                    <label className="form-check-label" htmlFor={option.ID_CATEGORIA}>
                      <strong>Codigo:</strong> {option.COD_CATEGORIA} - <strong>Descripcion:</strong> {option.DESC_CATEGORIA}
                    </label>
                  </div>
                ))}
              </form>
              <div className="Botones-Mensaje">
                <button type="button" class="btn btn-secondary" onClick={()=>{setMostrarConfigAlta(false); setMostrarPantallaABM(true); setCategoriasActivas(); setCategoriasSeleccionadas({}); setNombreTablaABMAlta('');}}>Cancelar</button>
                <button type="button" class="btn btn-success" onClick={(Object.keys(categoriasSeleccionadas).length === 0 || Object.entries(categoriasSeleccionadas).filter(([id, isSelected]) => isSelected).map(([id]) => id).length === 0)? ()=>{Alerta.fire({icon:'warning',title:'No se ha seleccionado ninguna opción.'})}: async ()=>{setMostrarConfigAlta(false); setMostrarConfig(true);await ConfigABMAlta(); setCategoriasActivas(); setCategoriasSeleccionadas({}); setNombreTablaABMAlta('');}}>Confirmar</button>
              </div>
            </>
            ):(<div></div>)}
          </form>
          { (!categoriasActivas) && (
            <div className="Botones-Mensaje">
              <button type="button" class="btn btn-secondary" onClick={()=>{setMostrarConfigAlta(false); setMostrarPantallaABM(true)}}>Cancelar</button>
              <button type="button" class="btn btn-success" onClick={manejarEnvioConfigAlta}>Confirmar</button>
            </div>
          )}
      </div>
      )}

      {mostrarConfig && (
        <>
          <div className='contenido-contenedor-abm contenedor-abm-RolesYPermisos'>
            <div className="Boton-VolverAUsuarios">
              <button type="button" className="btn btn-secondary" 
                onClick={() => { setItemSeleccionado(null); setMostrarConfig(false); setMostrarPantallaABM(true) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"/>
                  <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"/>
                </svg>{' '}Volver a Conexiones
              </button>
              {/*Alta*/}
              <button className="btn btn-success"
               onClick={()=>{setMostrarConfig(false); setMostrarConfigAlta(true)}}>   
                Añadir configuración{' '}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                </svg>
              </button>
            </div>
            <br/>
            <div className='titulo-contenido-contenedor-abm-usuarioconexiones'>
              <h3 className="text-wrap text-center">Configuraciones actuales de la conexión<br/><strong>{itemSeleccionado.Nombre}</strong></h3>
            </div>
            <br/>
            {(dataConfiguraciones.length !== 0)? 
            (
              <table className="table align-middle table-bordered table-hover tabla-abm-RolesYPermisos">
                <thead>
                  <tr >
                          <th className='text-center' scope='col'>Nombre</th>
                          <th className='text-center' scope='col'>Tabla fuente</th>
                          <th className='text-center' scope='col'>Codigos de categorías</th>
                          <th className="text-center" scope='col'>Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  {dataConfiguraciones.map((row, index) => (
                    <tr key={index}>
                      <td className='text-center'>{row.Nombre}</td>
                      <td className='text-center'>{row.Tabla}</td>
                      <td className='text-center'>{row.Codigos}</td>
                      <td className="text-center acciones-tabla-abm">
                        <button className="btn btn-outline-danger"
                          onClick={async()=>
                            {await RecargaAlerta.fire(
                              {icon:'warning',
                              title:'Atención!', 
                              text:`Si continúa se eliminará la configuracion ${row.Nombre}.`
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  bajaConfig(row.idConfig);
                                }
                              });
                            }
                          }
                        style={{width:100+'%'}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ):
            <h3>No hay configuraciones para mostrar.</h3>}
          </div>
        </>
      )}

      {mostrarPantallaABM && (
        <div className='contenido-contenedor-abm'>
            <div className="Boton-VolverAUsuarios">
              <button className="btn btn-secondary" onClick={VolverHome}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"/>
                  <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"/>
                </svg>
                {' '}Ir a Pantalla Principal</button>
              <button className="btn btn-success" hidden={(enSesion.TabConex)?false:true}
                onClick={() => {FuncionCargarEmpresas(); setMostrarMensajeAlta(true); setMostrarPantallaABM(false); }}> Añadir Conexón{' '}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                </svg>
              </button>
            </div>
            <br/>
          <div className='titulo-contenido-contenedor-abm'>
            <h3><strong>Conexiones</strong></h3>
            
          </div>
          {(data.length !== 0)?
            <>
            <table className="table align-middle table-bordered table-hover tabla-abm">
              <thead >
                <tr >
                  <th className='text-center text-wrap' scope='col'>Nombre</th>
                  <th className='text-center text-wrap' scope='col'>Activo</th>
                  <th className='text-center text-wrap' scope='col'>Servidor</th>
                  <th className='text-center text-wrap' scope='col'>Base de Datos</th>
                  <th className='text-center text-wrap' scope='col'>Empresa</th>
                  <th className='text-center text-wrap' scope='col' hidden={(enSesion.TabConex)?false:true}>Acciones</th>
                  <th className="text-center text-wrap" scope="col" hidden={(enSesion.TabConfig)?false:true}>Configuración para sueldo básico</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr className='text-center' key={index}>
                    <td className='text-center text-truncate'>{row.Nombre}</td>
                    <td className='text-center text-truncate'>{(row.Activo)?'Si':'No'}</td>
                    <td className='text-center text-truncate'>{row.Servidor}</td>
                    <td className='text-center text-truncate'>{row.BaseDatos}</td>
                    <td className='text-center text-truncate'>{row.Empresa}</td>
                    <td className="text-center acciones-tabla-abm" hidden={(enSesion.TabConex)?false:true}>
                      <button className="btn btn-outline-warning" style={{width:45+'%', marginRight: 2+'%'}} onClick={() => {setItemSeleccionado(row); setConexionActivaABMMod(row.Activo); manejarOpcionModificacion(row)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                        </svg>
                      </button>
                      <button className="btn btn-outline-danger" style={{width:45+'%', marginLeft: 2+'%'}} onClick={()=> {manejarBajaCon(row)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                        </svg>
                      </button>
                    </td>
                    {/*boton de configuracion de sueldo basico*/}

                    {(row.Activo)?
                      <><th className='text-center conexiones-usuarios-abm' hidden={(enSesion.TabConfig)?false:true}>
                          <button className="btn btn-outline-info" style={{ width: 100 + '%' }} 
                          onClick={async()=>{setItemSeleccionado(row); await funcionCargarConfiguraciones(row.idConexion); setMostrarPantallaABM(false); setMostrarConfig(true)}}>
                            Abrir {' '}
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5" />
                              <path fill-rule="evenodd" d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z" />
                            </svg>
                          </button>
                      </th></>
                      :
                      <><th className='text-center conexiones-usuarios-abm' >
                      <button className="btn btn-outline-dark" style={{ width: 100 + '%' }} onClick={()=>{Toast.fire({icon: "info", text:'Para utilizar esta función la conexión debe estar activa.'})}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
                          <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2"/>
                        </svg>
                      </button>
                      </th></>}
                  </tr>
                ))}
              </tbody>
            </table>
              </>:
            <>
              <h3 hidden={data.length !==0}>No hay registros para mostrar</h3>
            </>}
        </div>
      )}
    </div>
  ) 
}

export default ConexionesABM;
