import { useState, useRef } from "react";
import miScript from '../conexion.js';
import Swal from 'sweetalert2';

function RolesABM(
  { data, 
    cargandoset, 
    funcionCargarABM, 
    VolverHome }){

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  const Alerta = Swal.mixin({
    toast: false,
    position: "center",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  const RecargaAlerta = Swal.mixin({
    toast: false,
    position: "center",
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  const [itemSeleccionado, setItemSeleccionado] = useState(null);
  const [mostrarPantallaABM, setMostrarPantallaABM] = useState(true);
  const [mostrarMensajeAlta, setMostrarMensajeAlta] = useState(false);
  const [mostrarMensajeBaja, setMostrarMensajeBaja] = useState(false);
  const [mostrarMensajeMod, setMostrarMensajeMod] = useState(false);

  //Alta

  const [nombreRolABMAlta, setNombreRolABMAlta] = useState('');
  const [permAgregar, setPermAgregar] = useState(false);
  const [permEliminar, setPermEliminar] = useState(false);
  const [permActualizar, setPermActualizar] = useState(false);
  const [permEmpresas, setPermEmpresas] = useState(false);
  const [permConexiones, setPermConexiones] = useState(false);
  const [permConfiguracion, setPermConfiguracion] = useState(false);
  const [permRoles, setPermRoles] = useState(false);
  const [permUsuarios, setPermUsuarios] = useState(false);

  


  function manejarNombreABMAlta(e){
    e.preventDefault();
    setNombreRolABMAlta(e.target.value)
  }

  function manejarEnvioAlta(){
    if(nombreRolABMAlta===''){
      Alerta.fire({
        icon:"warning",
        title:'Complete el campo "Nombre del rol".'
      })
    }else{
      setMostrarMensajeAlta(false); 
      setMostrarPantallaABM(true); 
      RolesABMAlta()
    }
  }

  async function RolesABMAlta(){
    cargandoset(true);
    try{
      let insertar = await miScript.RolesABMAlta(nombreRolABMAlta,permAgregar,permEliminar,permActualizar,permEmpresas,permConexiones,permConfiguracion,permRoles,permUsuarios);
      if(insertar.ERROR){
        Toast.fire({
          icon: 'error',
          title: insertar.MENSAJE
        })
      }else{
        funcionCargarABM()
        Alerta.fire({
          icon:'success',
          title: insertar.MENSAJE
        })
      }
    }catch(err){
      Alerta.fire({
        icon:'error',
        title: 'Error: '+err
      })
    }
    cargandoset(false);
  }

  //Modificacion

  const [nuevoNombreABMMod, setNuevoNombreABMMod] = useState('');
  const [activarABMMod, setActivarABMMod] = useState(false);
  const [permAgregarABMMod, setPermAgregarABMMod] = useState(false);
  const [permEliminarABMMod, setPermEliminarABMMod] = useState(false);
  const [permActualizarABMMod, setPermActualizarABMMod] = useState(false);
  const [permEmpresasABMMod, setPermEmpresasABMMod] = useState(false);
  const [permConexionesABMMod, setPermConexionesABMMod] = useState(false);
  const [permConfiguracionABMMod, setPermConfiguracionABMMod] = useState(false);
  const [permRolesABMMod, setPermRolesABMMod] = useState(false);
  const [permUsuariosABMMod, setPermUsuariosABMMod] = useState(false);

  function setearEstados(row){
    setActivarABMMod(row.Activo)
    setPermAgregarABMMod(row.AgrCon)
    setPermEliminarABMMod(row.ElimCon)
    setPermActualizarABMMod(row.ActSueldo)
    setPermEmpresasABMMod(row.TabEmp)
    setPermConexionesABMMod(row.TabConex)
    setPermConfiguracionABMMod(row.TabConfig)
    setPermRolesABMMod(row.TabRoles)
    setPermUsuariosABMMod(row.TabUsuarios)
  }

  function manejarNuevoNombreABMMod(e){
    e.preventDefault();
    setNuevoNombreABMMod(e.target.value);
  }

  async function RolABMMod(item){
    cargandoset(true);
    try{
      let modificacion = await miScript.RolesABMMod(item,nuevoNombreABMMod,activarABMMod,permAgregarABMMod,permEliminarABMMod,permActualizarABMMod,permEmpresasABMMod,permConexionesABMMod,permConfiguracionABMMod,permRolesABMMod,permUsuariosABMMod);
      if(modificacion.ERROR){
        Alerta.fire({
          icon: "error",
          title: modificacion.MENSAJE
        });
      }else{
        funcionCargarABM();
        Alerta.fire({
          icon: "success",
          title: modificacion.MENSAJE
        });
      }
    }catch(err){
      Alerta.fire({
        icon: "error",
        title: "Fallo al conectar, intente nuevamente."
      });
    }
    cargandoset(false);
  }


  //Baja
  async function baja(item){
    cargandoset(true);
    try{
      let resultado = await miScript.RolesABMBaja(item)
      switch(resultado){
        case 1:{
          funcionCargarABM();
          Alerta.fire({
            icon: "success",
            title: "La baja del rol "+item.NombreRol+" ha sido existosa."
          });
          break;
        }
        case -1:{
          Alerta.fire({
            icon: "warning",
            title: "La baja no pudo efectuarse porque hay usuarios activos con este rol."
          });
          break;
        }
        case -2,-3:{
          Alerta.fire({
            icon: "error",
            title: "Fallo al dar la baja, intente nuevamente."
          });
          break;
        }
      }
    }catch(err){
      Alerta.fire({
        icon: "error",
        title: "Error: "+err
      });
    }
    cargandoset(false);
  }

  async function manejarBaja(row){
    
    RecargaAlerta.fire(
      {
        icon:'warning', 
        title:`¿Borrar el rol ${row.NombreRol}?`
      }).then((result)=>{
        if(result.isConfirmed){
          baja(row)
        }
      }
    )
  }


  return(
    <div className='contenedor-abm'>
      {mostrarMensajeAlta && (
        <div className="MensajeAlta">
            <h3>Añadir un Rol</h3>
            {/* Formulario para Alta */}
            <form className='form-group formulario-abm'>
              <div className='input-group mb-3'>
                <input type='text' placeholder='Nombre del rol' className='form-control' aria-label='tabla' aria-describedby='tabla' name='rol' onChange={manejarNombreABMAlta} required/>
              </div>
              <div>
                <p className="text-start"><strong>Permisos:</strong></p>
                <div className="form-check form-switch mb-1">
                  <input className="form-check-input" type="checkbox" role="switch" id="permAgregarABMAlta" value={permAgregar} onChange={(e)=>setPermAgregar(e.target.checked)} />
                  <label className="form-check-label" htmlFor="permAgregarABMAlta">Agregar conceptos:{(permAgregar) ? ' Permitir' : ' Denegar'}</label>
                </div>
                <div className="form-check form-switch mb-1">
                  <input className="form-check-input" type="checkbox" role="switch" id="permEliminarABMAlta" value={permEliminar} onChange={(e)=>setPermEliminar(e.target.checked)} />
                  <label className="form-check-label" htmlFor="permEliminarABMAlta">Eliminar conceptos:{(permEliminar) ? ' Permitir' : ' Denegar'}</label>
                </div>
                <div className="form-check form-switch mb-1">
                  <input className="form-check-input" type="checkbox" role="switch" id="permActualizarABMAlta" value={permActualizar} onChange={(e)=>setPermActualizar(e.target.checked)} />
                  <label className="form-check-label" htmlFor="permActualizarABMAlta">Actualización SB:{(permActualizar) ? ' Permitir' : ' Denegar'}</label>
                </div>
                <div className="form-check form-switch mb-1">
                  <input className="form-check-input" type="checkbox" role="switch" id="permEmpresasABMAlta" value={permEmpresas} onChange={(e)=>setPermEmpresas(e.target.checked)} />
                  <label className="form-check-label" htmlFor="permEmpresasABMAlta">Tabla empresas:{(permEmpresas) ? ' Permitir' : ' Denegar'}</label>
                </div>
                <div className="form-check form-switch mb-1">
                  <input className="form-check-input" type="checkbox" role="switch" id="permConexionesABMAlta" value={permConexiones} onChange={(e)=>setPermConexiones(e.target.checked)} />
                  <label className="form-check-label" htmlFor="permConexionesABMAlta">Tabla conexiones:{(permConexiones) ? ' Permitir' : ' Denegar'}</label>
                </div>
                <div className="form-check form-switch mb-1">
                  <input className="form-check-input" type="checkbox" role="switch" id="permConfiguracionABMAlta" value={permConfiguracion} onChange={(e)=>setPermConfiguracion(e.target.checked)} />
                  <label className="form-check-label" htmlFor="permConfiguracionABMAlta">Tabla Configuracion SB:{(permConfiguracion) ? ' Permitir' : ' Denegar'}</label>
                </div>
                <div className="form-check form-switch mb-1">
                  <input className="form-check-input" type="checkbox" role="switch" id="permRolesABMAlta" value={permRoles} onChange={(e)=>setPermRoles(e.target.checked)} />
                  <label className="form-check-label" htmlFor="permRolesABMAlta">Roles y Permisos:{(permRoles) ? ' Permitir' : ' Denegar'}</label>
                </div>
                <div className="form-check form-switch mb-1">
                  <input className="form-check-input" type="checkbox" role="switch" id="permUsuariosABMMod" value={permUsuarios} onChange={(e)=>setPermUsuarios(e.target.checked)} />
                  <label className="form-check-label" htmlFor="permUsuariosABMMod">Usuarios:{(permUsuarios) ? ' Permitir' : ' Denegar'}</label>
                </div>
              </div>
              <div className="Botones-Mensaje">
                <button type="button" class="btn btn-secondary" onClick={()=>{setMostrarMensajeAlta(false); setMostrarPantallaABM(true)}}>Cancelar</button>
                <button type="button" class="btn btn-success" onClick={manejarEnvioAlta}>Confirmar</button>
              </div>
            </form>
            
        </div>
      )}

      {mostrarMensajeMod && (
        <div className="MensajeMod">
          <h3>Modificar Rol</h3>
          <form className='form-group formulario-abm'>
            <div className='input-group mb-3'>
              <input type='text' placeholder={itemSeleccionado.NombreRol} className='form-control' aria-label='Tabla-ABMMod' aria-describedby='Tabla-ABMMod' onChange={manejarNuevoNombreABMMod}/>
            </div>
            <div className="form-check form-switch mb-1">
              <input className="form-check-input" type="checkbox" role="switch" id="activarABMMod" value={activarABMMod} onChange={(e)=>setActivarABMMod(e.target.checked)} checked={activarABMMod}/>
              <label className="form-check-label" htmlFor="activarABMMod">Estado del rol:{(activarABMMod) ? ' Activado' : ' Desactivado'}</label>
            </div>
            <div>
              <p className="text-start"><strong>Permisos:</strong></p>
              <div className="form-check form-switch mb-1">
                <input className="form-check-input" type="checkbox" role="switch" id="permAgregarABMMod" value={permAgregarABMMod} onChange={(e)=>setPermAgregarABMMod(e.target.checked)} checked={permAgregarABMMod}/>
                <label className="form-check-label" htmlFor="permAgregarABMMod">Agregar conceptos:{(permAgregarABMMod) ? ' Permitir' : ' Denegar'}</label>
              </div>
              <div className="form-check form-switch mb-1">
                <input className="form-check-input" type="checkbox" role="switch" id="permEliminarABMMod" value={permEliminarABMMod} onChange={(e)=>setPermEliminarABMMod(e.target.checked)} checked={permEliminarABMMod}/>
                <label className="form-check-label" htmlFor="permEliminarABMMod">Eliminar conceptos:{(permEliminarABMMod) ? ' Permitir' : ' Denegar'}</label>
              </div>
              <div className="form-check form-switch mb-1">
                <input className="form-check-input" type="checkbox" role="switch" id="permActualizarABMMod" value={permActualizarABMMod} onChange={(e)=>setPermActualizarABMMod(e.target.checked)} checked={permActualizarABMMod}/>
                <label className="form-check-label" htmlFor="permActualizarABMMod">Actualización SB:{(permActualizarABMMod) ? ' Permitir' : ' Denegar'}</label>
              </div>
              <div className="form-check form-switch mb-1">
                <input className="form-check-input" type="checkbox" role="switch" id="permEmpresasABMMod" value={permEmpresasABMMod} onChange={(e)=>setPermEmpresasABMMod(e.target.checked)} checked={permEmpresasABMMod}/>
                <label className="form-check-label" htmlFor="permEmpresasABMMod">Tabla empresas:{(permEmpresasABMMod) ? ' Permitir' : ' Denegar'}</label>
              </div>
              <div className="form-check form-switch mb-1">
                <input className="form-check-input" type="checkbox" role="switch" id="permConexionesABMMod" value={permConexionesABMMod} onChange={(e)=>setPermConexionesABMMod(e.target.checked)} checked={permConexionesABMMod}/>
                <label className="form-check-label" htmlFor="permConexionesABMMod">Tabla conexiones:{(permConexionesABMMod) ? ' Permitir' : ' Denegar'}</label>
              </div>
              <div className="form-check form-switch mb-1">
                <input className="form-check-input" type="checkbox" role="switch" id="permConfiguracionABMMod" value={permConfiguracionABMMod} onChange={(e)=>setPermConfiguracionABMMod(e.target.checked)} checked={permConfiguracionABMMod}/>
                <label className="form-check-label" htmlFor="permConfiguracionABMMod">Tabla Configuracion SB:{(permConfiguracionABMMod) ? ' Permitir' : ' Denegar'}</label>
              </div>
              <div className="form-check form-switch mb-1">
                <input className="form-check-input" type="checkbox" role="switch" id="permRolesABMMod" value={permRolesABMMod} onChange={(e)=>setPermRolesABMMod(e.target.checked)} checked={permRolesABMMod}/>
                <label className="form-check-label" htmlFor="permRolesABMMod">Roles y Permisos:{(permRolesABMMod) ? ' Permitir' : ' Denegar'}</label>
              </div>
              <div className="form-check form-switch mb-1">
                <input className="form-check-input" type="checkbox" role="switch" id="permUsuariosABMMod" value={permUsuariosABMMod} onChange={(e)=>setPermUsuariosABMMod(e.target.checked)} checked={permUsuariosABMMod}/>
                <label className="form-check-label" htmlFor="permUsuariosABMMod">Usuarios:{(permUsuariosABMMod) ? ' Permitir' : ' Denegar'}</label>
              </div>
            </div>
          </form>
          <div className="Botones-Mensaje">
            <button type="button" class="btn btn-secondary" onClick={()=>{setItemSeleccionado(null); setMostrarMensajeMod(false); setMostrarPantallaABM(true)}}>Cancelar</button>
            <button type="button" class="btn btn-primary" onClick={()=>{RolABMMod(itemSeleccionado); setMostrarMensajeMod(false); setMostrarPantallaABM(true)}}>Confirmar</button>
          </div>
        </div>)}

      {mostrarMensajeBaja && (<div className="MensajeBaja">
          <p>¿Borrar rol <strong>{itemSeleccionado.NombreRol}</strong>?</p>
          <div className="Botones-Mensaje">
            <button type="button" class="btn btn-secondary" onClick={()=>{setItemSeleccionado(null); setMostrarMensajeBaja(false)}}>Cancelar</button>
            <button type="button" class="btn btn-danger" onClick={()=>{baja(itemSeleccionado); setMostrarMensajeBaja(false); }}>Confirmar</button>
          </div>
      </div>)}

      {mostrarPantallaABM && (
        <>
        <div className='contenido-contenedor-abm contenedor-abm-RolesYPermisos'>
            <div className="Boton-VolverAUsuarios">
              <button className="btn btn-secondary" onClick={VolverHome}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"/>
                  <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"/>
                </svg>
                {' '}Ir a Pantalla Principal</button>
              <button className="btn btn-success"
                onClick={() => {setMostrarMensajeAlta(true); setMostrarPantallaABM(false);}}> Añadir Rol {' '}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                </svg>
              </button>
            </div>
            <br/>
          <div className='titulo-contenido-contenedor-abm'>
            <h3><strong>Roles y permisos</strong></h3>
            
          </div>
          <table className="table align-middle table-bordered table-hover tabla-abm-RolesYPermisos">
            <thead>
              <tr >
                <th className='text-center' scope='col'>Nombre del Rol</th>
                <th className='text-center' scope='col'>Activo</th>
                <th className='text-center' scope='col'>Agregar Conc.</th>
                <th className='text-center' scope='col'>Eliminar Conc.</th>
                <th className='text-center' scope='col'>Act. Sueldo</th>
                <th className='text-center' scope='col'>Tabla Empresa</th>
                <th className='text-center' scope='col'>Tabla Conex</th>
                <th className='text-center' scope='col'>Tabla Config</th>
                <th className='text-center' scope='col'>Tabla Roles</th>
                <th className='text-center' scope='col'>Tabla Usuarios</th>
                <th className='text-center' scope='col' style={{width:150+'px'}}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td className='text-center text-truncate'>{row.NombreRol}</td>
                  <td className='text-center'>{(row.Activo)?'Si':'No'}</td>
                  <td className='text-center'>{row.AgrCon?'Si':'No'}</td>
                  <td className='text-center'>{row.ElimCon?'Si':'No'}</td>
                  <td className='text-center'>{row.ActSueldo?'Si':'No'}</td>
                  <td className='text-center'>{row.TabEmp?'Si':'No'}</td>
                  <td className='text-center'>{row.TabConex?'Si':'No'}</td>
                  <td className='text-center'>{row.TabConfig?'Si':'No'}</td>
                  <td className='text-center'>{row.TabRoles?'Si':'No'}</td>
                  <td className='text-center'>{row.TabUsuarios?'Si':'No'}</td>
                  <td className="text-center acciones-tabla-abm">
                    {(row.NombreRol === 'ADMIN')?
                    <><button className="btn btn-outline-dark" style={{width:100+'%'}} onClick={() => {Toast.fire({icon:'info',title:'No se puede modificar ni eliminar el rol "ADMIN"'})}} >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
                        <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2"/>
                      </svg>
                    </button></>
                    :
                    <><button className="btn btn-outline-warning" style={{width:45+'%', marginRight: 2+'%'}} onClick={() => {setItemSeleccionado(row); setearEstados(row); setMostrarPantallaABM(false); setMostrarMensajeMod(true)}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                      </svg>
                    </button>
                    <button className="btn btn-outline-danger" style={{width:45+'%', marginLeft: 2+'%'}} onClick={()=> {manejarBaja(row)}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                      </svg>
                    </button></>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </>
      )}
    </div>
  )
}

export default RolesABM;